import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
    Accordion,
    Button,
    Datepicker,
    Modal,
    Textarea,
} from '@contentful/f36-components';
import { Form, FormControl, TextInput } from '@contentful/f36-forms';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import { ApiPhotoCompetitionStatus } from '../../models';
import { FormPhotoCompetitionProps } from '../../models/photoCompetitions';
import { formatDateToISOString } from '../../utilities/date-helper';

export const FormPhotoCompetition: React.FC<FormPhotoCompetitionProps> = (
    props
) => {
    const { formData, isVisible, setVisibility, setRefresh, setFormData } =
        props;

    const [accordionState, setAccordionState] = useState({
        1: true,
        2: false,
    });

    const handleExpand = (itemIndex: any) => () => {
        setAccordionState((state) => ({ ...state, [itemIndex]: true }));
    };

    const handleCollapse = (itemIndex: any) => () => {
        setAccordionState((state) => ({ ...state, [itemIndex]: false }));
    };

    const {
        handleSubmit,
        formState: { errors },
        reset,
        register,
    } = useForm();

    const { updateEntity, createEntity } = useVetdeskMiddleware();

    useEffect(() => {
        reset(formData || {});
    }, [formData]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (data: any) => {
        (formData && formData.id
            ? updateEntity(
                  `${ENV.middlewareBaseUrl}/api/v1/admin/event`,
                  formData.id,
                  {
                      ...formData,
                      ...data,
                      startDate: formatDateToISOString(data.startDate),
                      endDate: formatDateToISOString(data.endDate),
                  }
              )
            : createEntity(`${ENV.middlewareBaseUrl}/api/v1/admin/event`, {
                  ...data,
                  startDate: formatDateToISOString(data.startDate),
                  endDate: formatDateToISOString(data.endDate),
              })
        ).then(
            () => {
                reset({});
                setRefresh(true);
                setVisibility(false);
            },
            (error) => console.warn('Oops', error)
        );
    };

    return (
        <Modal
            onClose={() => setVisibility(false)}
            isShown={isVisible}
            size="900px"
            shouldCloseOnOverlayClick={false}
        >
            {() => {
                let startDateDisabled = false;
                let endDateDisabled = false;
                if (formData && formData.status) {
                    startDateDisabled =
                        formData.status === ApiPhotoCompetitionStatus.RUNNING
                            ? true
                            : formData.status ===
                                  ApiPhotoCompetitionStatus.ENDED ||
                              formData.status ===
                                  ApiPhotoCompetitionStatus.INACTIVE
                            ? true
                            : false;
                    endDateDisabled =
                        formData.status === ApiPhotoCompetitionStatus.ENDED ||
                        formData.status === ApiPhotoCompetitionStatus.INACTIVE
                            ? true
                            : false;
                }

                return (
                    <>
                        <Form
                            onSubmit={handleSubmit(onSubmit)}
                            style={{ overflowY: 'scroll' }}
                        >
                            <Modal.Header
                                title={`${
                                    formData && formData.id ? 'Edit' : 'Add New'
                                } Photo Competition`}
                                onClose={() => setVisibility(false)}
                            />
                            <Modal.Content>
                                <Accordion>
                                    <Accordion.Item
                                        title="Detail"
                                        isExpanded={accordionState[1]}
                                        onExpand={() => {
                                            setAccordionState((state) => ({
                                                ...state,
                                                [1]: true,
                                                [2]: false,
                                            }));
                                        }}
                                        //onCollapse={handleCollapse(1)}
                                    >
                                        <FormControl>
                                            <FormControl.Label>
                                                Title
                                            </FormControl.Label>
                                            <TextInput {...register(`title`)} />
                                        </FormControl>
                                        <FormControl>
                                            <FormControl.Label>
                                                Description
                                            </FormControl.Label>
                                            <Textarea
                                                {...register(`description`)}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormControl.Label>
                                                Prize
                                            </FormControl.Label>
                                            <TextInput {...register(`prize`)} />
                                        </FormControl>
                                        <FormControl style={{ minWidth: 120 }}>
                                            <FormControl.Label>
                                                Start Date
                                            </FormControl.Label>
                                            <Datepicker
                                                selected={
                                                    formData &&
                                                    formData.startDate
                                                        ? new Date(
                                                              formData.startDate
                                                          )
                                                        : new Date()
                                                }
                                                onSelect={(val) => {
                                                    const data = {
                                                        ...formData,
                                                        startDate: val,
                                                    };
                                                    setFormData(data);
                                                }}
                                                inputProps={{
                                                    isDisabled:
                                                        startDateDisabled,
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl style={{ minWidth: 120 }}>
                                            <FormControl.Label>
                                                End Date
                                            </FormControl.Label>
                                            <Datepicker
                                                selected={
                                                    formData && formData.endDate
                                                        ? new Date(
                                                              formData.endDate
                                                          )
                                                        : new Date()
                                                }
                                                onSelect={(val) => {
                                                    const data = {
                                                        ...formData,
                                                        endDate: val,
                                                    };
                                                    setFormData(data);
                                                }}
                                                inputProps={{
                                                    isDisabled: endDateDisabled,
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl style={{ minWidth: 120 }}>
                                            <FormControl.Label>
                                                How it works?
                                            </FormControl.Label>
                                            <Textarea {...register(`rules`)} />
                                        </FormControl>
                                    </Accordion.Item>
                                    <Accordion.Item
                                        title="Terms and Conditions"
                                        isExpanded={accordionState[2]}
                                        onExpand={() => {
                                            setAccordionState((state) => ({
                                                ...state,
                                                [1]: false,
                                                [2]: true,
                                            }));
                                        }}
                                        //onCollapse={handleCollapse(2)}
                                    >
                                        <FormControl style={{ minWidth: 120 }}>
                                            <Textarea
                                                rows={15}
                                                {...register(`terms`)}
                                            />
                                        </FormControl>
                                    </Accordion.Item>
                                </Accordion>
                            </Modal.Content>
                            <Modal.Controls>
                                <Button
                                    size="small"
                                    variant="transparent"
                                    onClick={() => {
                                        reset();
                                        setVisibility(false);
                                    }}
                                >
                                    Close
                                </Button>
                                <Button
                                    size="small"
                                    variant="positive"
                                    type="submit"
                                >
                                    {formData && formData.id
                                        ? 'Save'
                                        : 'Create'}
                                </Button>
                            </Modal.Controls>
                        </Form>
                    </>
                );
            }}
        </Modal>
    );
};
