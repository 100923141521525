import React from 'react';

import {
    Card,
    formatDateAndTime,
    Modal,
    Table,
} from '@contentful/f36-components';

import { FundraiserDetailsModalProps, FundraiserProduct } from '../models';

export const FundraiserDetailsModal: React.FC<FundraiserDetailsModalProps> = (
    props
) => {
    const { fundraiser, isVisible, setVisibility } = props;

    const renderFundraiserDetails = () => {
        if (!fundraiser) return <></>;

        return (
            <Card style={{ marginBottom: 25 }}>
                <div>
                    <div>
                        <strong>Name:</strong> {fundraiser.name}
                    </div>

                    <div>
                        <strong>Active from:</strong>{' '}
                        {formatDateAndTime(fundraiser.activeFrom)}
                    </div>
                    <div>
                        <strong>Active until:</strong>{' '}
                        {formatDateAndTime(fundraiser.activeUntil)}
                    </div>
                    <div>
                        <strong>Pay on pickup:</strong>{' '}
                        {fundraiser.payOnPickup ? 'Yes' : 'No'}
                    </div>
                </div>
            </Card>
        );
    };

    const renderFundraiserProducts = () => {
        return (
            <Table>
                <Table.Head>
                    <Table.Row>
                        <Table.Cell>Product</Table.Cell>
                        <Table.Cell>Price</Table.Cell>
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {fundraiser!.products.map((product: FundraiserProduct) => (
                        <Table.Row key={product.id}>
                            <Table.Cell>{product.name}</Table.Cell>
                            <Table.Cell>${product.price.toFixed(2)}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    };

    return (
        <Modal
            onClose={() => setVisibility(false)}
            isShown={isVisible}
            size="1200px"
            shouldCloseOnOverlayClick={false}
        >
            <div style={{ overflowY: 'scroll' }}>
                <Modal.Header
                    title="Fundraiser detail"
                    onClose={() => setVisibility(false)}
                />
                <Modal.Content>
                    {fundraiser && fundraiser.id && renderFundraiserDetails()}
                    {fundraiser &&
                        (fundraiser.products || []).length > 0 &&
                        renderFundraiserProducts()}
                </Modal.Content>
            </div>
        </Modal>
    );
};
