import React, { useEffect } from 'react';
import Select, { MultiValue } from 'react-select';

import { Spinner, Stack } from '@contentful/f36-components';

import { ENV } from '../environments';
import { useVetdeskMiddleware } from '../hooks';
import { ProductTag, ProductTagCollection, TagTypeEnums } from '../models';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/product/tag`;

export interface TagMultiselectProps {
    values: string[];
    onTagsSelect: (ids: ProductTag[]) => void;
    tagType: TagTypeEnums;
    placeholder?: string;
}

export const TagMultiselect: React.FC<TagMultiselectProps> = ({
    onTagsSelect,
    values,
    tagType,
    placeholder,
}) => {
    const [tags, setTags] = React.useState<ProductTag[]>([]);
    const { fetchEntity } = useVetdeskMiddleware<ProductTag>();
    const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        fetchEntity(API_URL).then((res) => {
            const response = res as ProductTagCollection;
            switch (tagType) {
                case TagTypeEnums.BRANDS:
                    setTags(response.brands);
                    break;
                case TagTypeEnums.PETTYPES:
                    setTags(response.petTypes);
                    break;
                case TagTypeEnums.CATEGORIES:
                default:
                    setTags(response.categories);
                    break;
            }
            setLoading(false);
        });
        setSelectedTags(values);
    }, []);

    useEffect(
        () =>
            onTagsSelect(
                selectedTags.map((c) => ({
                    id: c,
                    name: tags.find((cat) => cat.id === c)?.name || '',
                }))
            ),
        [selectedTags]
    );

    const getTagOptions = (tags: ProductTag[]) => {
        return tags.map((x) => ({
            value: x.id,
            label: x.name,
        }));
    };

    return (
        <Stack flexDirection="column" alignItems="start">
            <div style={{ width: '100%', zIndex: 999 }}>
                {loading ? (
                    <Stack flexDirection="row">
                        <Spinner
                            style={{ marginTop: 20 }}
                            variant="primary"
                            size="medium"
                        />
                    </Stack>
                ) : (
                    <Select
                        closeMenuOnSelect={false}
                        options={getTagOptions(tags)}
                        value={getTagOptions(tags).filter((c) =>
                            selectedTags.includes(c.value)
                        )}
                        onChange={(values: MultiValue<any>) => {
                            const result: string[] = [];
                            values.map((val: any) => {
                                const findTag = tags.find(
                                    (t) => t.id == val.value
                                );

                                if (findTag) result.push(findTag.id);
                            });
                            setSelectedTags(result);
                        }}
                        isMulti
                        placeholder={placeholder}
                    />
                )}
            </div>
        </Stack>
    );
};
