import React, { useEffect, useState } from 'react';

import {
    Button,
    Flex,
    Heading,
    Stack,
    TextInput,
} from '@contentful/f36-components';
import { PlusIcon, SearchIcon } from '@contentful/f36-icons';

import { FormCoupon } from '../components/forms/form-coupon';
import { PaginationWrapper } from '../components/pagination-wrapper';
import { TableList } from '../components/table-list';
import { ENV } from '../environments';
import { useDebounce, useVetdeskMiddleware } from '../hooks';
import { ApiEntityStatus, ApiListResponse } from '../models';
import { Coupon } from '../models/coupons';
import {
    DEFAULT_FETCH_LIST_FILTERS,
    FetchListFilters,
    getQueryString,
} from '../utilities/fetch-filters';
import { COUPON_COLUMNS } from '../utilities/table-columns';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/coupon`;

export const PageCoupons = () => {
    const { fetchEntityList, runRequest } = useVetdeskMiddleware<Coupon>();
    const [coupons, setCoupons] = useState<ApiListResponse<Coupon> | undefined>(
        undefined
    );
    const [refresh, setRefresh] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState(null);

    const [filters, setFilters] = useState<FetchListFilters>({
        ...DEFAULT_FETCH_LIST_FILTERS,
    });

    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const handleToggleStatus = (item: Coupon, status: ApiEntityStatus) => {
        runRequest('PUT', `${API_URL}/${item.id}/status`).then(
            (res) => {
                setRefresh(true);
            },
            (error) => console.warn('Oops', error)
        );
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUpsert = (formData?: any) => {
        setFormData(formData || null);
        setShowForm(true);
    };

    const fetchCoupons = () => {
        setCoupons(undefined); // to display skeleton when fetching
        fetchEntityList(`${API_URL}${getQueryString(filters)}`).then((res) => {
            res.items.forEach((item) => {
                item.enableValidToDate = item.validToDate != null;
            });
            setCoupons(res);
        });
    };

    useEffect(() => {
        fetchCoupons();
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchCoupons();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            setFilters({
                ...filters,
                search: debouncedSearchInput,
                offset: 0,
            });
        }
    }, [debouncedSearchInput]);

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>Coupons</Heading>
                <Button
                    startIcon={<PlusIcon />}
                    variant="primary"
                    size="medium"
                    className="addButton"
                    onClick={() => handleUpsert()}
                >
                    Add New
                </Button>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search..."
                    icon={<SearchIcon />}
                />
                <TableList<Coupon>
                    columns={COUPON_COLUMNS}
                    items={coupons?.items}
                    onEdit={handleUpsert}
                    onToggleStatus={handleToggleStatus}
                    showActiveOnToggleStatusLabel={true}
                />
                {coupons && (
                    <PaginationWrapper
                        onPageChange={handlePageChange}
                        filters={filters}
                        totalItems={coupons.total}
                    />
                )}
            </Flex>
            {showForm && (
                <FormCoupon
                    formData={formData}
                    setRefresh={setRefresh}
                    setVisibility={setShowForm}
                    isVisible={showForm}
                    setFormData={setFormData}
                />
            )}
        </>
    );
};
