import React, { useEffect } from 'react';

import { Pagination } from '@contentful/f36-components';

import { FetchListFilters } from '../utilities/fetch-filters';

export interface PaginationWrapperProps {
    onPageChange: (age: number) => void;
    filters: FetchListFilters;
    totalItems: number;
}

export const PaginationWrapper: React.FC<PaginationWrapperProps> = ({
    onPageChange,
    filters,
    totalItems,
}) => {
    const { limit, offset } = filters;

    useEffect(() => {
        console.log(
            `Limit: ${limit} - Offset: ${offset} - Total Items: ${totalItems} - Active Page: ${
                filters.offset / filters.limit
            } - Is Last Page: ${offset * limit >= totalItems}`
        );
    }, []);

    return (
        <Pagination
            activePage={filters.offset / filters.limit || 0}
            onPageChange={onPageChange}
            itemsPerPage={limit}
            isLastPage={offset + limit >= totalItems}
            totalItems={totalItems}
            className="spacingTop"
        />
    );
};
