import { Notification } from '@contentful/f36-components';

import { useAuth } from '../auth/authProvider';
import { ApiErrorResponse, ApiListResponse } from '../models';

export const useVetdeskMiddleware = <T>() => {
    const { accessToken } = useAuth();

    // generic private async request handler
    const runRequest = async <S>(
        method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH' | 'HEAD',
        url: string,
        payload?: Partial<T>
    ): Promise<S> => {
        const body = JSON.stringify(payload);

        // build and sign request
        const signedRequest = {
            method: method,
            path: new URL(url).pathname,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Accept: 'application/json, text/plain',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${accessToken}`,
            },
            body,
        };

        // run request
        return (await fetch(
            url,
            method === 'GET' || method === 'DELETE'
                ? signedRequest
                : {
                      ...signedRequest,
                      body,
                  }
        )
            .then(async (response) => {
                // error handling
                if (!response.ok) {
                    const errorResponse: ApiErrorResponse =
                        await response.json();
                    console.error(`An error has occured:`, errorResponse);
                    throw new Error(errorResponse.message);
                }

                if (method !== 'GET') {
                    Notification.setPlacement('top');
                    Notification.success('Request Successful!');
                }

                // return json response
                const jsonResponse: S = await response.json();
                return jsonResponse;
            })
            .catch((err) => {
                Notification.setPlacement('top');
                Notification.error(
                    err.message === 'Failed to fetch'
                        ? 'Middleware API is not available.'
                        : err.message
                );
                throw new Error(err); //To prevent the modal refresh
            })) as S;
    };

    /**
     * Fetch (GET) entity list
     * @param url
     * @returns Promise<T>
     */
    const fetchEntityList = async (url: string) => {
        return await runRequest<ApiListResponse<T>>('GET', url);
    };

    /**
     * Fetch (GET) entity
     * @param url
     * @returns Promise<T>
     */
    const fetchEntity = async (url: string) => {
        return await runRequest('GET', url);
    };

    /**
     * public create (POST) data method
     * @param url
     * @param payload
     * @returns Promise<T>
     */
    const createEntity = async (url: string, payload: Partial<T>) => {
        return await runRequest('POST', url, payload);
    };

    /**
     * public update (PUT) data method
     * @param url
     * @param payload
     * @returns Promise<T>
     */
    const updateEntity = async (
        url: string,
        entityId: string,
        payload: Partial<T>
    ) => {
        return await runRequest('PUT', `${url}/${entityId}`, payload);
    };

    /**
     * public delete (DELETE) data method
     * @param url
     * @param payload
     * @returns Promise<T>
     */
    const deleteEntity = async (url: string, entityId: string) => {
        return await runRequest('DELETE', `${url}/${entityId}`);
    };

    return {
        fetchEntityList,
        fetchEntity,
        createEntity,
        updateEntity,
        deleteEntity,
        runRequest,
    };
};
