import '../styles/page.css';

import React, { useEffect, useState } from 'react';

import {
    Button,
    Checkbox,
    Flex,
    Heading,
    Stack,
    TextInput,
} from '@contentful/f36-components';
import { EditIcon, PlusIcon, SearchIcon } from '@contentful/f36-icons';

import { CategoryPicker } from '../components/category-picker';
import { EditProductsModal } from '../components/edit-products-modal';
import { PaginationWrapper } from '../components/pagination-wrapper';
import { BulkEditModal } from '../components/product/bulk-edit-modal';
import { SupplierProductsModal } from '../components/supplier-products-modal';
import { TableList } from '../components/table-list';
import { TagMultiselect } from '../components/tag-multiselect';
import { ENV } from '../environments';
import { useDebounce } from '../hooks';
import { useVetdeskMiddleware } from '../hooks/use-vetdesk-middleware';
import { ApiEntityStatus, ApiListResponse } from '../models';
import { Product, TagTypeEnums } from '../models/products';
import {
    DEFAULT_FETCH_LIST_FILTERS,
    getQueryString,
    ProductFetchListFilters,
} from '../utilities/fetch-filters';
import { PRODUCT_COLUMNS } from '../utilities/table-columns';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/product`;

export const PageProducts = () => {
    const { fetchEntityList, updateEntity, deleteEntity } =
        useVetdeskMiddleware<Product>();

    const [products, setProducts] = useState<
        ApiListResponse<Product> | undefined
    >(undefined);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<ProductFetchListFilters>({
        ...DEFAULT_FETCH_LIST_FILTERS,
        requiresAttention: false,
        brands: '',
    });
    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);

    const [showNewForm, setShowNewForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [formData, setFormData] = useState(null);
    const [requiresAttention, setRequiresAttention] = useState(false);
    const [SelectedProducts, setSelectedProducts] = useState<Product[]>([]);
    const [showBulkEditForm, setShowBulkEditForm] = useState(false);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const handleToggleStatus = (item: Product, status: ApiEntityStatus) => {
        updateEntity(API_URL, item.id, {
            ...item,
            images: undefined,
            status,
        }).then(() => {
            setRefresh(true);
        });
    };

    const handleInsert = () => {
        setFormData(null);
        setShowNewForm(true);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUpdate = (formData?: any) => {
        setFormData(formData || null);
        setShowEditForm(true);
    };

    const handleDelete = (itemId: string) => {
        deleteEntity(API_URL, itemId).then(() => {
            setRefresh(true);
        });
    };

    const fetchProducts = () => {
        setProducts(undefined); // to display skeleton when fetching
        fetchEntityList(`${API_URL}${getQueryString(filters)}`).then((res) => {
            res.items.map((x) => (x.selectable = true)); // true by default for all the products
            setProducts(res);
        });
    };

    useEffect(() => {
        fetchProducts();
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchProducts();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            setFilters({
                ...filters,
                search: debouncedSearchInput,
                offset: 0,
            });
        }
    }, [debouncedSearchInput]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (requiresAttention !== filters.requiresAttention) {
            setFilters({
                ...filters,
                requiresAttention,
                offset: 0,
            });
        }
    }, [requiresAttention]);

    const handleProductSelect = (product: Product[]) => {
        setSelectedProducts(product);
    };

    const handleBulkEdit = () => {
        setShowBulkEditForm(true);
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>Products</Heading>
                <div>
                    <Button
                        startIcon={<PlusIcon />}
                        variant="primary"
                        size="medium"
                        className="addButton"
                        onClick={() => handleInsert()}
                    >
                        Add New
                    </Button>{' '}
                    <Button
                        startIcon={<EditIcon />}
                        variant="primary"
                        size="medium"
                        onClick={() => handleBulkEdit()}
                    >
                        Bulk edit
                    </Button>
                </div>
            </Stack>
            <Stack justifyContent="space-between">
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search..."
                    icon={<SearchIcon />}
                />
                <CategoryPicker
                    onCategorySelect={(c) =>
                        setFilters({
                            ...filters,
                            categories: c ? c.id : '',
                            offset: 0,
                            limit: filters.limit,
                        })
                    }
                />
                <div style={{ minWidth: '30%' }}>
                    <TagMultiselect
                        values={[]}
                        onTagsSelect={(v) => {
                            const brands = v
                                .map(function (item) {
                                    return item.id;
                                })
                                .join(',');

                            // if statement to avoid calling the fetch endpoint twice during mounting
                            if (brands !== filters.brands) {
                                setFilters({
                                    ...filters,
                                    brands: brands,
                                    offset: 0,
                                    limit: filters.limit,
                                });
                            }
                        }}
                        tagType={TagTypeEnums.BRANDS}
                        placeholder="Filter by brands"
                    />
                </div>
                <div style={{ minWidth: '12%' }}>
                    <Checkbox
                        name="requires-attention"
                        id="requires-attention"
                        isChecked={requiresAttention}
                        onChange={() =>
                            setRequiresAttention(!requiresAttention)
                        }
                    >
                        Requires attention
                    </Checkbox>
                </div>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TableList<Product>
                    columns={PRODUCT_COLUMNS}
                    items={products?.items}
                    onEdit={handleUpdate}
                    onToggleStatus={handleToggleStatus}
                    onDelete={handleDelete}
                    onSelectionChange={handleProductSelect}
                />
                {products && (
                    <PaginationWrapper
                        onPageChange={handlePageChange}
                        filters={filters}
                        totalItems={products.total}
                    />
                )}
            </Flex>
            {showNewForm && (
                <SupplierProductsModal
                    formData={formData}
                    setRefresh={setRefresh}
                    setVisibility={setShowNewForm}
                    isVisible={showNewForm}
                />
            )}
            {showEditForm && (
                <EditProductsModal
                    formData={formData || ({} as Product)}
                    setRefresh={setRefresh}
                    setVisibility={setShowEditForm}
                    isVisible={showEditForm}
                />
            )}
            {showBulkEditForm && (
                <BulkEditModal
                    selectedProducts={SelectedProducts}
                    setRefresh={setRefresh}
                    onClose={() => setShowBulkEditForm(false)}
                />
            )}
        </>
    );
};
