import React from 'react';
import ReactDOM from 'react-dom/client';

import { GlobalStyles } from '@contentful/f36-components';

import App from './app';
import { AuthProvider } from './auth/authProvider';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <AuthProvider>
            <GlobalStyles />
            <App />
        </AuthProvider>
    </React.StrictMode>
);
