import { DateRangeEnum } from '../components/date-range-picker';
import { ENV } from '../environments';

export const DashboardUrl = `${ENV.middlewareBaseUrl}/api/v1/admin/dashboard`;

export interface DashboardConfig {
    widgets: {
        [widgetName: string]: DashboardWidgetConfig;
    };
}

export interface DashboardWidgetConfig {
    hide: boolean;
    dateRange: DateRangeEnum;
}

export enum DashboardWidgets {
    sales = 'sales',
    stats = 'stats',
    topSellers = 'topSellers',
    appointments = 'appointments',
    appDownloads = 'appDownloads',
}

export interface DashboardStats {
    customers: number;
    leaderboardEntries: number;
    orders: number;
    subscriptions: number;
}

export interface DashboardAppointments {
    clinicId: string;
    clinicName: string;
    pendingAppointments: number;
    confirmedAppointments: number;
    cancelledAppointments: number;
}

export interface TopSellers {
    id?: string;
    name: string;
    revenue: number;
    profit: number;
    unitSold: number;
}

export interface Sales {
    salesDetails: { [dataKey: string]: SalesDetails[] };
    total: number;
    profit: number;
    revenuePercentage: number;
    profitPercentage: number;
}

export interface SalesDetails {
    total: number;
    profit: number;
}

export interface AppDownloads {
    android: { [dataKey: string]: AppDownloadsDetails };
    ios: { [dataKey: string]: AppDownloadsDetails };
    iosTotal: number;
    androidTotal: number;
}

export interface AppDownloadsDetails {
    [dataKey: string]: number;
}
