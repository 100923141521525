import React, { useEffect, useState } from 'react';

import { Flex, Heading, Stack, TextInput } from '@contentful/f36-components';
import { SearchIcon } from '@contentful/f36-icons';

import { FormSubscription } from '../components/forms/form-subscription';
import { PaginationWrapper } from '../components/pagination-wrapper';
import { TableList } from '../components/table-list';
import { ENV } from '../environments';
import { useDebounce, useVetdeskMiddleware } from '../hooks';
import { ApiListResponse, Subscription } from '../models';
import {
    DEFAULT_FETCH_LIST_FILTERS,
    FetchListFilters,
    getQueryString,
} from '../utilities/fetch-filters';
import { SUBSCRIPTION_COLUMNS } from '../utilities/table-columns';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/subscription`;

export const PageSubscriptions = () => {
    const { fetchEntityList, deleteEntity } =
        useVetdeskMiddleware<Subscription>();
    const [subscriptions, setSubscriptions] = useState<
        ApiListResponse<Subscription> | undefined
    >(undefined);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<FetchListFilters>(
        DEFAULT_FETCH_LIST_FILTERS
    );

    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState(null);

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const handleUpsert = (formData?: any) => {
        setFormData(formData || null);
        setShowForm(true);
    };

    const handleDelete = (itemId: string) => {
        deleteEntity(API_URL, itemId).then(() => {
            setRefresh(true);
        });
    };

    const fetchSubscriptions = () => {
        setSubscriptions(undefined); // to display skeleton when fetching
        fetchEntityList(`${API_URL}${getQueryString(filters)}`).then((res) => {
            setSubscriptions(res);
        });
    };

    useEffect(() => {
        fetchSubscriptions();
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchSubscriptions();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            setFilters({
                ...filters,
                search: debouncedSearchInput,
                offset: 0,
            });
        }
    }, [debouncedSearchInput]);

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>Subscriptions</Heading>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search..."
                    icon={<SearchIcon />}
                />
                <TableList<Subscription>
                    columns={SUBSCRIPTION_COLUMNS}
                    items={subscriptions?.items}
                    onView={handleUpsert}
                    // onDelete={handleDelete}
                />
                {subscriptions && (
                    <PaginationWrapper
                        onPageChange={handlePageChange}
                        filters={filters}
                        totalItems={subscriptions.total}
                    />
                )}
            </Flex>
            {showForm && (
                <FormSubscription
                    formData={formData}
                    setRefresh={setRefresh}
                    setVisibility={setShowForm}
                    isVisible={showForm}
                />
            )}
        </>
    );
};
