import React, { useEffect } from 'react';

import { Select, Stack } from '@contentful/f36-components';

import { ENV } from '../environments';
import { useVetdeskMiddleware } from '../hooks';
import { VetCategory, VetProductCategory } from '../models';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/category?limit=1000`;

export interface CategoryPickerProps {
    onCategorySelect: (catId: VetProductCategory | undefined) => void;
}

export const CategoryPicker: React.FC<CategoryPickerProps> = ({
    onCategorySelect: onCategoriesSelect,
}) => {
    const [categories, setCategories] = React.useState<VetCategory[]>([]);

    const { fetchEntityList } = useVetdeskMiddleware<VetCategory>();

    //const [selectedCategories, setSelectedCategories] = React.useState<string>();

    useEffect(() => {
        fetchEntityList(API_URL).then((res) => {
            setCategories(res.items);
        });
    }, []);

    return (
        <Stack flexDirection="column" alignItems="start">
            <Select
                style={{ width: '350px' }}
                id="supplier"
                name="supplier"
                defaultValue=""
                onChange={(event) =>
                    onCategoriesSelect(
                        categories.find(
                            (c) => c.id.toString() === event.target.value
                        )
                    )
                }
            >
                <Select.Option value="">Filter by category</Select.Option>
                {categories &&
                    categories.map((cat) => (
                        <Select.Option key={cat.id} value={cat.id}>
                            {cat.name}
                        </Select.Option>
                    ))}
            </Select>
        </Stack>
    );
};
