import React, { useEffect } from 'react';

import { Select } from '@contentful/f36-components';

import { ENV } from '../environments';
import { useVetdeskMiddleware } from '../hooks';
import { Clinic } from '../models';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/clinic`;

export interface ClinicSelectProps {
    value: string;
    onSelect: (clinic: Clinic) => void;
}

export const ClinicSelect: React.FC<ClinicSelectProps> = ({
    onSelect,
    value,
}) => {
    const [dataItems, setDataItems] = React.useState<Clinic[]>([]);

    const { fetchEntityList } = useVetdeskMiddleware<Clinic>();
    const [selected, setSelected] = React.useState<string | undefined>(value);

    // This function will be called once the user selects an item in the list of options
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSelectItem = (event: any) => {
        const item = dataItems.find((x) => x.id === event.target.value);
        item && onSelect(item);
        item && setSelected(item.id);
    };

    useEffect(() => {
        fetchEntityList(API_URL).then((res) => {
            setDataItems(res.items);
        });
    }, []);

    return (
        <Select value={selected} onChange={handleSelectItem}>
            <Select.Option value="" isDisabled>
                Pick one...
            </Select.Option>
            {dataItems.map((x, i) => {
                return (
                    <Select.Option key={i} value={x.id}>
                        {x.name}
                    </Select.Option>
                );
            })}
        </Select>
    );
};
