import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
    Badge,
    Button,
    Card,
    CopyButton,
    Flex,
    formatDateAndTime,
    Modal,
    Text,
} from '@contentful/f36-components';
import { Form } from '@contentful/f36-forms';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import {
    Address,
    FormOrderProps,
    Order,
    OrderCourierTracking,
    PaymentMethod,
} from '../../models';
import { PaymentTransaction } from '../../models/paymentTransaction';
import {
    BadgeVariantType,
    getBadgeVariant,
} from '../../utilities/badge-helper';
import { BasketDetail } from '../basket-detail';

export const FormOrder: React.FC<FormOrderProps> = (props) => {
    const { formData, isVisible, setVisibility, setRefresh, isFundraiser } =
        props;
    const {
        handleSubmit,
        //formState: { errors },
        reset,
    } = useForm();

    const { updateEntity } = useVetdeskMiddleware();

    useEffect(() => {
        reset(formData || {});
    }, [formData]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (data: any) => {
        if (formData.id) {
            updateEntity(
                `${ENV.middlewareBaseUrl}/api/v1/admin/order`,
                formData.id,
                {
                    ...formData,
                    ...data,
                }
            ).then(
                () => {
                    reset({});
                    setRefresh(true);
                    setVisibility(false);
                },
                (error) => console.warn('Oops', error)
            );
        }
    };

    // const getKeysFromEnum = () => {
    //     return Object.keys(ApiOrderEntityStatus) as Array<
    //         keyof typeof ApiOrderEntityStatus
    //     >;
    // };

    const renderStripePaymentIdentifier = (
        paymentTransaction: PaymentTransaction
    ) => {
        return (
            <Flex alignItems="center">
                <strong>Stripe payment identifier:</strong>&nbsp;
                {paymentTransaction?.gatewayReference}
                {paymentTransaction && (
                    <CopyButton
                        size="small"
                        style={{
                            borderRadius: '50%',
                            marginLeft: 5,
                        }}
                        value={paymentTransaction?.gatewayReference}
                        tooltipProps={{
                            placement: 'right',
                        }}
                    />
                )}
            </Flex>
        );
    };

    const renderAddress = (address: Address) => {
        return (
            <div>
                <strong>Address:</strong>{' '}
                {address.company ? <Text>{`${address.company}, `}</Text> : null}
                {address.street ? <Text>{`${address.street}, `}</Text> : null}
                {address.suburb ? <Text>{`${address.suburb}, `}</Text> : null}
                {address.city ? <Text>{`${address.city} `}</Text> : null}
                {address.postCode ? <Text>{`${address.postCode}`}</Text> : null}
            </div>
        );
    };

    const renderPaymentMethod = (paymentMethod: PaymentMethod) => {
        return (
            <div>
                <strong>Payment method:</strong>{' '}
                <Text>{`******${paymentMethod.cardNumber}, `}</Text>
                <Text>{`Type: ${paymentMethod.brand}, `}</Text>
                <Text>{`Exp: ${paymentMethod.expiryMonth}/${paymentMethod.expiryYear}`}</Text>
            </div>
        );
    };

    const renderCourierTrackingUrl = (
        courierTracking: OrderCourierTracking
    ) => {
        return (
            <div>
                <Flex alignItems="center">
                    <strong>Tracking Status:</strong>&nbsp;
                    <Badge
                        variant={getBadgeVariant(
                            courierTracking.status,
                            BadgeVariantType.OrderTracking
                        )}
                    >
                        {courierTracking.status}
                    </Badge>
                    {!!courierTracking.trackingUrl && (
                        <Button
                            variant="secondary"
                            size="small"
                            onClick={() =>
                                window.open(
                                    courierTracking.trackingUrl,
                                    '_blank'
                                )
                            }
                            as="a"
                            style={{ marginLeft: '4px' }}
                        >
                            Track Parcel
                        </Button>
                    )}
                </Flex>
            </div>
        );
    };

    const getOrderDetail = (formData: Order) => {
        if (!formData || !formData.basket) return <></>;

        return (
            <Card style={{ marginBottom: 25 }}>
                <Flex justifyContent="space-between" alignItems="center">
                    <div>
                        <div>
                            <strong>Number</strong> # {formData.number}
                        </div>

                        <div>
                            <strong>Customer:</strong> {formData.username}
                        </div>
                        {formData.address && renderAddress(formData.address)}
                        <div>
                            <strong>Created date:</strong>{' '}
                            {formatDateAndTime(formData.createdDate)}
                        </div>
                    </div>
                    <div>
                        <Flex alignItems="center">
                            <strong>Order Status:</strong>&nbsp;
                            <Badge
                                variant={getBadgeVariant(
                                    formData.status,
                                    BadgeVariantType.Order
                                )}
                            >
                                {formData.status}
                            </Badge>
                        </Flex>
                        {formData.paymentTransaction &&
                            renderStripePaymentIdentifier(
                                formData.paymentTransaction
                            )}
                        {formData.paymentMethod &&
                            renderPaymentMethod(formData.paymentMethod)}
                        {(formData.courierTracking || []).length > 0 &&
                            renderCourierTrackingUrl(
                                formData.courierTracking![0]
                            )}
                    </div>
                </Flex>
            </Card>
        );
    };

    return (
        <Modal
            onClose={() => setVisibility(false)}
            isShown={isVisible}
            size="1200px"
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ overflowY: 'scroll' }}
                    >
                        <Modal.Header
                            title="Order detail"
                            onClose={() => setVisibility(false)}
                        />
                        <Modal.Content>
                            {formData &&
                                formData.id &&
                                getOrderDetail(formData)}
                            {formData && formData.basket && (
                                <BasketDetail
                                    basket={formData.basket}
                                    coupon={formData.coupon}
                                    discountedTotal={
                                        formData.totalAfterCouponRedemption
                                    }
                                    taxAfterDiscount={
                                        formData.taxAfterCouponRedemption
                                    }
                                    isFundraiser={isFundraiser}
                                />
                            )}
                        </Modal.Content>
                    </Form>
                </>
            )}
        </Modal>
    );
};
