import React, { useState } from 'react';

import {
    Asset,
    Button,
    Flex,
    Form,
    FormControl,
    Heading,
    Modal,
    Table,
    TextInput,
} from '@contentful/f36-components';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import {
    BulkEditProduct,
    Product,
    TagTypeEnums,
    VetProductCategory,
} from '../../models';
import { TagMultiselect } from '../tag-multiselect';

export interface BulkEditProps {
    selectedProducts: Product[];
    onClose: () => void;
    setRefresh: (refresh: boolean) => void;
}

export const BulkEditModal: React.FC<BulkEditProps> = (props) => {
    const { runRequest } = useVetdeskMiddleware();

    const [selectedCategories, setSelectedCategories] = useState<
        VetProductCategory[] | null
    >(null);
    const [selectedMarginPercentage, setSelectedMarginPercentage] = useState<
        string | null
    >(null);

    const [keywords, setKeywords] = useState<string>('');

    const handleCategoryChange = (values: VetProductCategory[]) => {
        setSelectedCategories(values);
    };

    const handleSubmit = () => {
        const body: BulkEditProduct = {
            productIds: props.selectedProducts.map((x) => x.id),
            categories: selectedCategories,
            marginPercent: selectedMarginPercentage
                ? Number(selectedMarginPercentage)
                : null,
            discountedPrice: null,
            keywords,
        };
        runRequest(
            'PUT',
            `${ENV.middlewareBaseUrl}/api/v1/admin/product/bulkprice`,
            body
        ).then(
            () => {
                props.setRefresh(true);
                props.onClose();
            },
            (error) => console.warn('Oops', error)
        );
    };

    const renderProductDetail = () => {
        return (
            <Table>
                <Table.Head>
                    <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>Name</Table.Cell>
                        <Table.Cell>Current categories</Table.Cell>
                        <Table.Cell>Supplier price</Table.Cell>
                        <Table.Cell>Current Margin %</Table.Cell>
                        <Table.Cell>Current selling price</Table.Cell>
                        <Table.Cell>Discounted price</Table.Cell>
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {props.selectedProducts.map((x, i) => (
                        <Table.Row key={i}>
                            <Table.Cell>
                                <Asset
                                    src={x.images && x.images[0].url}
                                    style={{ maxWidth: 100 }}
                                />
                            </Table.Cell>
                            <Table.Cell>{x.name}</Table.Cell>
                            <Table.Cell>
                                {x.categories.map((x) => x.name).join(', ')}
                            </Table.Cell>
                            <Table.Cell>
                                ${x.supplierPriceWithTax.toFixed(2)}
                            </Table.Cell>
                            <Table.Cell>
                                {x.marginPercent.toFixed(2)}%
                            </Table.Cell>
                            <Table.Cell>${x.price.toFixed(2)}</Table.Cell>
                            <Table.Cell>
                                {x.discountedPrice ? (
                                    <>
                                        <s>${x.price.toFixed(2)}</s> $
                                        {x.discountedPrice.toFixed(2)}
                                    </>
                                ) : (
                                    <>-</>
                                )}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    };

    const renderContent = () => {
        return (
            <Form>
                <Flex
                    fullWidth
                    gap="spacingM"
                    justifyContent="normal"
                    style={{
                        borderBottom: '2px solid #bfbcbc',
                        paddingBottom: 8,
                        marginBottom: 16,
                    }}
                >
                    <FormControl style={{ minWidth: 300, width: 400 }}>
                        <FormControl.Label>
                            Override categories
                        </FormControl.Label>
                        <TagMultiselect
                            values={[]}
                            onTagsSelect={(v) => handleCategoryChange(v)}
                            tagType={TagTypeEnums.CATEGORIES}
                        />
                    </FormControl>
                    <FormControl style={{ minWidth: 300, width: 400 }}>
                        <FormControl.Label>
                            Override margin percentage
                        </FormControl.Label>
                        <TextInput.Group>
                            <Button variant="secondary">%</Button>
                            <TextInput
                                onChange={(e) =>
                                    setSelectedMarginPercentage(e.target.value)
                                }
                            />
                        </TextInput.Group>
                    </FormControl>
                    <FormControl style={{ minWidth: 300, width: 400 }}>
                        <FormControl.Label>Override keywords</FormControl.Label>
                        <TextInput.Group>
                            <TextInput
                                onChange={(e) => setKeywords(e.target.value)}
                            />
                        </TextInput.Group>
                    </FormControl>
                </Flex>
                <FormControl style={{ minHeight: 300 }}>
                    <Heading>
                        {props.selectedProducts.length} Products are selected to
                        update together.
                    </Heading>
                    {renderProductDetail()}
                </FormControl>
            </Form>
        );
    };

    return (
        <Modal
            onClose={props.onClose}
            isShown={true}
            size="fullWidth"
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header
                        title={'Bulk Edit Products'}
                        onClose={props.onClose}
                    />

                    <Modal.Content>
                        {props.selectedProducts.length > 1
                            ? renderContent()
                            : 'Please select multiple products before continuing.'}
                    </Modal.Content>
                    <Modal.Controls>
                        <Button
                            size="small"
                            variant="transparent"
                            onClick={props.onClose}
                        >
                            Close
                        </Button>
                        {props.selectedProducts.length > 0 ? (
                            <Button
                                size="small"
                                variant="positive"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Modal.Controls>
                </>
            )}
        </Modal>
    );
};
