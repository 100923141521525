import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

import {
    Button,
    Checkbox,
    Modal,
    Stack,
    Textarea,
} from '@contentful/f36-components';
import { Form, FormControl, TextInput } from '@contentful/f36-forms';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import { FormNotificationProps } from '../../models/notifications';
import { formatDateToISOString } from '../../utilities/date-helper';
import { DatetimePicker } from '../datetime-picker';

const availableNotificationLinks = [
    'None',
    'Appointments',
    'Leaderboard',
    'Pets',
    'Profile',
    'Shop',
    'Subscriptions',
];

export const FormNotification: React.FC<FormNotificationProps> = (props) => {
    const {
        formData,
        isVisible,
        setVisibility,
        setRefresh,
        availableRecipients,
    } = props;

    const {
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        register,
        control,
    } = useForm();

    const { updateEntity, createEntity } = useVetdeskMiddleware();

    const [selectAllRecipients, setSelectAllRecipients] = useState(false);

    const [sendNow, setSendNow] = useState(false);

    useEffect(() => {
        if (
            formData &&
            formData.recipients.length == 1 &&
            formData.recipients[0] == '*'
        ) {
            setSelectAllRecipients(true);
        }
        reset(formData || {});
    }, [formData]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (data: any) => {
        (formData && formData.id
            ? updateEntity(
                  `${ENV.middlewareBaseUrl}/api/v1/admin/notification`,
                  formData.id,
                  {
                      ...formData,
                      ...data,
                      scheduledDate: formatDateToISOString(data.scheduledDate),
                  }
              )
            : createEntity(
                  `${ENV.middlewareBaseUrl}/api/v1/admin/notification`,
                  {
                      ...data,
                      scheduledDate: formatDateToISOString(data.scheduledDate),
                  }
              )
        ).then(
            () => {
                reset({});
                setRefresh(true);
                setVisibility(false);
            },
            (error) => console.warn('Oops', error)
        );
    };

    return (
        <Modal
            onClose={() => {
                setVisibility(false);
                reset(formData || {});
            }}
            isShown={isVisible}
            size="900px"
            shouldCloseOnOverlayClick={false}
        >
            {() => {
                return (
                    <>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Modal.Header
                                title={`${
                                    formData && formData.id ? 'Edit' : 'Add New'
                                } Notification`}
                                onClose={() => setVisibility(false)}
                            />
                            <Modal.Content>
                                <FormControl>
                                    <FormControl.Label>Title</FormControl.Label>
                                    <TextInput {...register(`title`)} />
                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>Body</FormControl.Label>
                                    <Textarea {...register(`body`)} />
                                </FormControl>
                                <FormControl style={{ zIndex: 10 }}>
                                    <FormControl.Label>Link</FormControl.Label>
                                    <Controller
                                        control={control}
                                        name="link"
                                        render={({
                                            field: { onChange, value },
                                        }) => {
                                            const options =
                                                availableNotificationLinks.map(
                                                    (x) => ({
                                                        value: x.toLowerCase(),
                                                        label: x,
                                                    })
                                                );
                                            return (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Select
                                                        value={options.filter(
                                                            (c) =>
                                                                value == c.value
                                                        )}
                                                        onChange={(val) => {
                                                            const selected =
                                                                options.filter(
                                                                    (c) =>
                                                                        c.value ==
                                                                        val?.value
                                                                );
                                                            onChange(
                                                                selected[0]
                                                                    .value
                                                            );
                                                        }}
                                                        options={options}
                                                        isMulti={false}
                                                    />
                                                </div>
                                            );
                                        }}
                                    />
                                </FormControl>
                                <FormControl style={{ zIndex: 9 }}>
                                    <FormControl.Label>
                                        Recipients
                                    </FormControl.Label>
                                    <Stack
                                        flexDirection="column"
                                        alignItems="start"
                                    >
                                        <Checkbox
                                            isChecked={selectAllRecipients}
                                            onChange={(event) => {
                                                if (
                                                    event.target.checked == true
                                                ) {
                                                    setValue(`recipients`, [
                                                        '*',
                                                    ]);
                                                    setSelectAllRecipients(
                                                        true
                                                    );
                                                } else {
                                                    setSelectAllRecipients(
                                                        false
                                                    );
                                                    setValue(`recipients`, []);
                                                }
                                            }}
                                        >
                                            Send to all recipients
                                        </Checkbox>
                                        {' or, '}

                                        <Controller
                                            control={control}
                                            name="recipients"
                                            render={({
                                                field: { onChange, value },
                                            }) => {
                                                const options =
                                                    availableRecipients.map(
                                                        (x) => ({
                                                            value: x.userId,
                                                            label: x.username,
                                                        })
                                                    );
                                                return (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Select
                                                            value={options.filter(
                                                                (c) =>
                                                                    value ==
                                                                    c.value
                                                            )}
                                                            onChange={(val) => {
                                                                onChange(
                                                                    val.map(
                                                                        (c) =>
                                                                            c.value
                                                                    )
                                                                );
                                                            }}
                                                            options={options}
                                                            isMulti
                                                            isDisabled={
                                                                selectAllRecipients ==
                                                                true
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <FormControl.Label>
                                        Scheduled For
                                    </FormControl.Label>
                                    <Stack
                                        flexDirection="column"
                                        alignItems="start"
                                    >
                                        <Checkbox
                                            isChecked={sendNow}
                                            onChange={(event) => {
                                                if (
                                                    event.target.checked == true
                                                ) {
                                                    setSendNow(true);
                                                    setValue(
                                                        `scheduledDate`,
                                                        parseInt(
                                                            new Date()
                                                                .getTime()
                                                                .toFixed(0)
                                                        )
                                                    );
                                                } else {
                                                    setSendNow(false);
                                                }
                                            }}
                                        >
                                            Now
                                        </Checkbox>

                                        <DatetimePicker
                                            isDisabled={sendNow}
                                            onSelect={(date) =>
                                                date &&
                                                setValue(
                                                    'scheduledDate',
                                                    parseInt(
                                                        date
                                                            .getTime()
                                                            .toFixed(0)
                                                    )
                                                )
                                            }
                                        />
                                    </Stack>
                                </FormControl>
                            </Modal.Content>
                            <Modal.Controls>
                                <Button
                                    size="small"
                                    variant="transparent"
                                    onClick={() => {
                                        reset();
                                        setVisibility(false);
                                    }}
                                >
                                    Close
                                </Button>
                                <Button
                                    size="small"
                                    variant="positive"
                                    type="submit"
                                >
                                    {formData && formData.id
                                        ? 'Save'
                                        : 'Create'}
                                </Button>
                            </Modal.Controls>
                        </Form>
                    </>
                );
            }}
        </Modal>
    );
};
