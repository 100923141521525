import React, { useEffect } from 'react';

import { FormControl, Select } from '@contentful/f36-components';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import { Supplier } from '../../models';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/supplier`;

export interface SupplierPickerProps {
    onSupplierSelect: (supplier: Supplier | undefined) => void;
}

export const SupplierPicker: React.FC<SupplierPickerProps> = ({
    onSupplierSelect,
}) => {
    const [suppliers, setSuppliers] = React.useState<Supplier[]>([]);

    const { fetchEntityList } = useVetdeskMiddleware<Supplier>();

    useEffect(() => {
        fetchEntityList(API_URL).then((res) => {
            setSuppliers(res.items);
        });
    }, []);

    return (
        <FormControl>
            <FormControl.Label>Select the product supplier</FormControl.Label>
            <Select
                id="supplier"
                name="supplier"
                defaultValue=""
                onChange={(event) =>
                    onSupplierSelect(
                        suppliers.find((s) => s.id === event.target.value)
                    )
                }
            >
                <Select.Option value="" isDisabled>
                    Pick one...
                </Select.Option>
                {suppliers.map((supplier) => (
                    <Select.Option key={supplier.id} value={supplier.id}>
                        {supplier.name}
                    </Select.Option>
                ))}
            </Select>
        </FormControl>
    );
};
