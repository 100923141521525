import React, { useEffect, useState } from 'react';
import {
    CartesianGrid,
    Cell,
    Line,
    LineChart,
    Pie,
    PieChart,
    Tooltip as RechartTooltip,
    XAxis,
    YAxis,
} from 'recharts';

import {
    Box,
    Card,
    Skeleton,
    Stack,
    Subheading,
} from '@contentful/f36-components';

import { useVetdeskMiddleware } from '../../hooks';
import {
    DashboardUrl,
    DashboardWidgetConfig,
    Sales,
} from '../../models/dashboard';
import { DateRangeEnum, DateRangePicker } from '../date-range-picker';

export const DashboardSalesPage: React.FC<DashboardWidgetConfig> = (props) => {
    const { runRequest } = useVetdeskMiddleware<any>();

    const [salesLoading, setSalesLoading] = useState(true);
    const [sales, setSales] = useState<Sales | undefined>(undefined);
    const [salesRange, setSalesRange] = useState<DateRangeEnum>(
        props.dateRange
    );

    // sales
    useEffect(() => {
        setSalesLoading(true);
        runRequest('GET', `${DashboardUrl}/sales/${salesRange}`).then((res) => {
            setSalesLoading(false);
            setSales(res as Sales);
        });
    }, [salesRange]);

    // Convert salesDetails to the desired format
    const transformSalesData = (data: Sales) => {
        const transformedData: {
            year: string;
            month: string;
            total: number;
            profit: number;
        }[] = [];

        Object.entries(data.salesDetails).forEach(([year, yearData]) => {
            yearData.forEach((monthData) => {
                Object.entries(monthData).forEach(([month, details]) => {
                    transformedData.push({
                        year: year,
                        month: `${year}-${capitalizeFirstLetter(month)}`,
                        total: details.total,
                        profit: details.profit,
                    });
                });
            });
        });
        return transformedData;
    };

    // Convert salesDetails to the desired format
    const transformSalesDataForPie = (data: Sales) => {
        const transformedData: any = [
            {
                name: 'Profit',
                value: data.profitPercentage,
                color: '#8073b5',
            },
            {
                name: 'Revenue',
                value: data.revenuePercentage,
                color: '#1d4c84',
            },
        ];

        return transformedData;
    };

    const capitalizeFirstLetter = (string: string): string => {
        // Check if the input string is empty or null
        if (!string) {
            return string;
        }
        // Capitalize the first letter and concatenate the rest of the string
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <>
            <Box style={{ marginTop: 25, width: '25%' }}>
                {sales && (
                    <Card isLoading={salesLoading}>
                        <Subheading style={{ marginBottom: 40 }}>
                            Total Sales: ${sales.total.toFixed(2)}
                        </Subheading>
                        <PieChart width={300} height={300}>
                            <Pie
                                data={transformSalesDataForPie(sales)}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                innerRadius={30}
                                outerRadius={70}
                                label={({ name, value }) =>
                                    `${name}: ${value}%`
                                }
                            >
                                {transformSalesDataForPie(sales).map(
                                    (entry: any, index: any) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={entry.color}
                                        />
                                    )
                                )}
                            </Pie>
                        </PieChart>
                    </Card>
                )}
            </Box>
            <Box style={{ marginTop: 25, width: '75%' }}>
                {sales && (
                    <Card>
                        <Stack
                            justifyContent="space-between"
                            style={{ marginBottom: 25 }}
                        >
                            <Subheading>Sales</Subheading>
                            <DateRangePicker
                                value={salesRange}
                                onSelect={(v) =>
                                    setSalesRange(v as DateRangeEnum)
                                }
                            />
                        </Stack>
                        {salesLoading ? (
                            <Skeleton.Container>
                                <Skeleton.BodyText numberOfLines={4} />
                            </Skeleton.Container>
                        ) : (
                            <LineChart
                                width={900}
                                height={300}
                                data={transformSalesData(sales)}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" interval={2} />
                                <YAxis />
                                <RechartTooltip />
                                {/* <Legend /> */}
                                <Line
                                    type="monotone"
                                    dataKey="total"
                                    stroke="#8884d8"
                                    name="Total"
                                />
                                <Line
                                    type="monotone"
                                    dataKey="profit"
                                    stroke="#82ca9d"
                                    name="Profit"
                                />
                            </LineChart>
                        )}
                    </Card>
                )}
            </Box>
        </>
    );
};
