import React, { useEffect, useState } from 'react';

import { Box, Card, Flex, Heading, Skeleton } from '@contentful/f36-components';

import { DashboardAppDownloadsPage } from '../components/dashboard/dashboard-app-downloads';
import { DashboardAppointmentsPage } from '../components/dashboard/dashboard-appointments';
import { DashboardSalesPage } from '../components/dashboard/dashboard-sales';
import { DashboardStatsPage } from '../components/dashboard/dashboard-stats';
import { DashboardTopSellersPage } from '../components/dashboard/dashboard-top-sellers';
import { DateRangeEnum } from '../components/date-range-picker';
import { useVetdeskMiddleware } from '../hooks';
import {
    DashboardConfig,
    DashboardUrl,
    DashboardWidgetConfig,
    DashboardWidgets,
} from '../models/dashboard';

export const PageDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [configs, setConfigs] = useState<DashboardConfig | undefined>(
        undefined
    );
    const { runRequest } = useVetdeskMiddleware<any>();

    // Define a function to return default widget for a given widget name
    const getDefaultConfigForWidget = (
        widgetName: DashboardWidgets
    ): DashboardWidgetConfig => {
        switch (widgetName) {
            case DashboardWidgets.appDownloads:
                return { hide: false, dateRange: DateRangeEnum.thisYear };
            case DashboardWidgets.sales:
                return { hide: false, dateRange: DateRangeEnum.thisYear };
            case DashboardWidgets.stats:
                return { hide: false, dateRange: DateRangeEnum.thisMonth };
            case DashboardWidgets.topSellers:
                return { hide: false, dateRange: DateRangeEnum.thisYear };
            case DashboardWidgets.appointments:
                return { hide: false, dateRange: DateRangeEnum.thisMonth };
            default:
                throw new Error('Unknown widget name');
        }
    };

    // Check if config.Widgets has the specified widgets, otherwise return default values
    const getWidgetConfig = (
        widgetName: DashboardWidgets
    ): DashboardWidgetConfig => {
        // eslint-disable-next-line no-prototype-builtins
        if (configs && configs.widgets.hasOwnProperty(widgetName)) {
            return configs.widgets[widgetName];
        } else {
            return getDefaultConfigForWidget(widgetName);
        }
    };

    // check if the widget is visible
    const isWidgetHidden = (widgetName: DashboardWidgets): boolean => {
        // eslint-disable-next-line no-prototype-builtins
        if (configs && configs.widgets.hasOwnProperty(widgetName)) {
            return configs.widgets[widgetName].hide;
        } else {
            return false; //must hide from vetconfig. visible by default
        }
    };

    // config
    useEffect(() => {
        setLoading(true);
        runRequest('GET', `${DashboardUrl}/configuration`).then((res) => {
            setConfigs(res as DashboardConfig);
            setLoading(false);
        });
    }, []);

    return (
        <>
            <Heading>Dashboard</Heading>

            {loading || !configs ? (
                <Skeleton.Container>
                    <Skeleton.BodyText numberOfLines={4} />
                </Skeleton.Container>
            ) : (
                <>
                    <Flex flexDirection="row" gap="spacingS">
                        {!isWidgetHidden(DashboardWidgets.appDownloads) && (
                            <DashboardAppDownloadsPage
                                {...getWidgetConfig(
                                    DashboardWidgets.appDownloads
                                )}
                            />
                        )}
                    </Flex>

                    <Flex flexDirection="row" gap="spacingS">
                        {!isWidgetHidden(DashboardWidgets.sales) && (
                            <DashboardSalesPage
                                {...getWidgetConfig(DashboardWidgets.sales)}
                            />
                        )}
                    </Flex>

                    {!isWidgetHidden(DashboardWidgets.stats) && (
                        <Card style={{ marginTop: 25 }}>
                            <DashboardStatsPage
                                {...getWidgetConfig(DashboardWidgets.stats)}
                            />
                        </Card>
                    )}

                    <Flex flexDirection="row" gap="spacingS">
                        {!isWidgetHidden(DashboardWidgets.topSellers) && (
                            <Box style={{ marginTop: 25, width: '50%' }}>
                                <DashboardTopSellersPage
                                    {...getWidgetConfig(
                                        DashboardWidgets.topSellers
                                    )}
                                />
                            </Box>
                        )}

                        {!isWidgetHidden(DashboardWidgets.appointments) && (
                            <Box style={{ marginTop: 25, width: '50%' }}>
                                <DashboardAppointmentsPage
                                    {...getWidgetConfig(
                                        DashboardWidgets.appointments
                                    )}
                                />
                            </Box>
                        )}
                    </Flex>
                </>
            )}
        </>
    );
};
