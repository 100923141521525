import React, { useEffect, useState } from 'react';

import { Modal } from '@contentful/f36-components';

import { ENV } from '../environments';
import { useVetdeskMiddleware } from '../hooks';
import { ApiListResponse } from '../models';
import {
    PhotoCompetition,
    PhotoCompetitionDetailModalProps,
    PhotoCompetitionEntry,
} from '../models/photoCompetitions';
import {
    DEFAULT_FETCH_LIST_FILTERS,
    getQueryString,
} from '../utilities/fetch-filters';
import { PHOTO_COMPETITION_ENTRY_COLUMNS } from '../utilities/table-columns';
import { EntryDetail } from './entry-detail';
import { PaginationWrapper } from './pagination-wrapper';
import { TableList } from './table-list';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/`;

export const FILTERS_ENTRY = {
    eventGuid: '',
    userGuid: '',
};

export const PhotoCompetitionDetail: React.FC<
    PhotoCompetitionDetailModalProps
> = (props) => {
    const { formData, setVisibility, isVisible } = props;
    const { fetchEntityList } = useVetdeskMiddleware();

    const [filters, setFilters] = useState({
        ...DEFAULT_FETCH_LIST_FILTERS,
        ...FILTERS_ENTRY,
    });
    const [entries, setEntries] = useState<
        ApiListResponse<PhotoCompetitionEntry> | undefined
    >(undefined);

    const [refresh, setRefresh] = useState(false);

    const [showEntryDetail, setShowEntryDetail] = useState<
        PhotoCompetitionEntry | undefined
    >(undefined);

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const fetchEntries = (event: PhotoCompetition) => {
        const entryFilters = filters;
        entryFilters.eventGuid = event.id;
        entryFilters.userGuid = '';
        fetchEntityList(`${API_URL}entry${getQueryString(entryFilters)}`).then(
            (res) => {
                const resp = res as ApiListResponse<PhotoCompetitionEntry>;
                // mapping socialShareUrl into entries
                resp.items.map(
                    (x) => (x.socialShareUrl = event.socialShareUrl)
                );
                setEntries(resp);
            }
        );
    };

    useEffect(() => {
        fetchEntries(formData);
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchEntries(formData);

            setRefresh(false);
        }
    }, [refresh]);

    const handleViewDetail = (entry: PhotoCompetitionEntry) => {
        setShowEntryDetail(entry);
    };

    return (
        <Modal
            onClose={() => setVisibility(false)}
            isShown={isVisible}
            size="fullWidth"
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header
                        title="Event Details"
                        onClose={() => setVisibility(false)}
                    />
                    <Modal.Content>
                        <TableList<PhotoCompetitionEntry>
                            columns={PHOTO_COMPETITION_ENTRY_COLUMNS}
                            items={entries?.items}
                            onView={handleViewDetail}
                        />
                        {entries && (
                            <PaginationWrapper
                                onPageChange={handlePageChange}
                                filters={filters}
                                totalItems={entries.total}
                            />
                        )}
                        {showEntryDetail && (
                            <EntryDetail
                                entry={showEntryDetail}
                                onClose={() => setShowEntryDetail(undefined)}
                                onUpdate={() => {
                                    setRefresh(true);
                                    setShowEntryDetail(undefined);
                                }}
                            />
                        )}
                    </Modal.Content>
                </>
            )}
        </Modal>
    );
};
