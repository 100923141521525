export interface FetchListFilters {
    search: string;
    limit: number;
    offset: number;
    sortBy?: string;
    sortOrderList?: 'asc' | 'desc';
}

export const DEFAULT_FETCH_LIST_FILTERS = {
    search: '',
    limit: 10,
    offset: 0,
};

export const getQueryString = (obj: FetchListFilters) =>
    `?${new URLSearchParams({
        ...obj,
        offset: obj.offset.toString(),
        limit: obj.limit.toString(),
    }).toString()}`;

export interface ProductFetchListFilters extends FetchListFilters {
    categories?: string;
    brands?: string;
    requiresAttention?: boolean;
}

export interface AppointmentFetchListFilters extends FetchListFilters {
    userId?: string;
    type?: AppointmentFetchType;
}

export enum AppointmentFetchType {
    All = 'All',
    New = 'New',
    Declined = 'Declined',
    Confirmed = 'Confirmed',
    Archived = 'Archived',
}

export interface OrderFetchListFilters extends FetchListFilters {
    anonymous?: boolean;
    isFundraiser?: boolean;
}

export enum OrderFetchType {
    Normal = 'Normal',
    Anonymous = 'Anonymous',
    Fundraiser = 'Fundraiser',
}

export enum CustomerFetchType {
    Registered = 'Registered',
    Anonymous = 'Anonymous',
}

export enum OneOffInvoiceFetchType {
    Normal = 'Normal',
    Anonymous = 'Anonymous',
}

export interface OneOffInvoiceFetchListFilters extends FetchListFilters {
    anonymous?: boolean;
}

export interface CustomerFetchListFilters extends FetchListFilters {
    anonymous?: boolean;
}
