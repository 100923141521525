import format from 'date-fns/format';

export const formatDateToISOString = (date: Date): string => {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
};

export const getTimeFromDate = (date: Date) => {
    date = new Date(date);
    // Get the individual components of the time
    let hours = date.getHours();
    const minutes = date.getMinutes();
    // const seconds = date.getSeconds();

    // Determine whether it's AM or PM
    const amPM = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 12-hour clock

    // Format the time string
    const timeString =
        hours.toString().padStart(2, '0') +
        ':' +
        minutes.toString().padStart(2, '0') +
        ' ' +
        amPM;

    return timeString;
};

export const calculateAgeFromDate = (dob: Date) => {
    const EPOCH = new Date(0);
    const EPOCH_YEAR = EPOCH.getUTCFullYear();
    const EPOCH_MONTH = EPOCH.getUTCMonth();
    //const EPOCH_DAY = EPOCH.getUTCDate();
    const diff = new Date(Date.now() - new Date(dob).getTime());

    const years = Math.abs(diff.getUTCFullYear() - EPOCH_YEAR);
    const months = Math.abs(diff.getUTCMonth() - EPOCH_MONTH);
    //const days = Math.abs(diff.getUTCDate() - EPOCH_DAY);

    if (years <= 0) {
        return `${months} Month/s`;
    } else {
        return `${years} Year/s ${months} Month/s`;
    }
};
