import React, { useEffect, useState } from 'react';

import { Flex, Heading, Stack, TextInput } from '@contentful/f36-components';
import { SearchIcon } from '@contentful/f36-icons';

import { FundraiserDetailsModal } from '../components/fundraiser-details-modal';
import { PaginationWrapper } from '../components/pagination-wrapper';
import { TableList } from '../components/table-list';
import { ENV } from '../environments';
import { useDebounce, useVetdeskMiddleware } from '../hooks';
import { ApiListResponse, Fundraiser } from '../models';
import {
    DEFAULT_FETCH_LIST_FILTERS,
    FetchListFilters,
    getQueryString,
} from '../utilities/fetch-filters';
import { FUNDRAISER_COLUMNS } from '../utilities/table-columns';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/fundraiser`;

export const PageFundraisers = () => {
    const { fetchEntityList } = useVetdeskMiddleware<Fundraiser>();
    const [fundraisers, setFundraisers] = useState<
        ApiListResponse<Fundraiser> | undefined
    >(undefined);
    const [refresh, setRefresh] = useState(false);

    const [filters, setFilters] = useState<FetchListFilters>({
        ...DEFAULT_FETCH_LIST_FILTERS,
    });

    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);
    const [showDetails, setShowDetails] = useState(false);
    const [fundraiserDetails, setFundraiserDetails] = useState<
        Fundraiser | undefined
    >(undefined);

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const handleView = (data?: Fundraiser) => {
        setFundraiserDetails(data);
        setShowDetails(true);
    };

    const fetchFundraisers = () => {
        setFundraisers(undefined); // to display skeleton when fetching
        fetchEntityList(`${API_URL}${getQueryString(filters)}`).then((res) => {
            setFundraisers(res);
        });
    };

    useEffect(() => {
        fetchFundraisers();
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchFundraisers();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            setFilters({
                ...filters,
                search: debouncedSearchInput,
                offset: 0,
            });
        }
    }, [debouncedSearchInput]);

    const renderTable = () => {
        return (
            <>
                <TableList<Fundraiser>
                    columns={FUNDRAISER_COLUMNS}
                    items={fundraisers?.items}
                    onView={handleView}
                    // onDelete={handleDelete}
                />
                {fundraisers && (
                    <PaginationWrapper
                        onPageChange={handlePageChange}
                        filters={filters}
                        totalItems={fundraisers.total}
                    />
                )}
            </>
        );
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>Fundraisers</Heading>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search..."
                    icon={<SearchIcon />}
                />
                {renderTable()}
            </Flex>
            <FundraiserDetailsModal
                fundraiser={fundraiserDetails}
                setVisibility={setShowDetails}
                isVisible={showDetails}
            />
        </>
    );
};
