import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
    Button,
    Checkbox,
    Datepicker,
    FormControl,
    Modal,
    Select,
    TextInput,
} from '@contentful/f36-components';
import { Form } from '@contentful/f36-forms';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import { FormCouponProps } from '../../models/coupons';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/coupon`;

export const FormCoupon: React.FC<FormCouponProps> = (props) => {
    const { formData, isVisible, setVisibility, setRefresh, setFormData } =
        props;
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const { createEntity, updateEntity } = useVetdeskMiddleware();

    useEffect(() => {
        reset(formData || {});
    }, [formData]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (data: any) => {
        data.validToDate = data.enableValidToDate ? data.validToDate : null;
        (formData && formData.id
            ? updateEntity(API_URL, formData.id, {
                  ...formData,
                  ...data,
              })
            : createEntity(API_URL, {
                  ...data,
              })
        ).then(
            () => {
                reset({});
                setRefresh(true);
                setVisibility(false);
            },
            (error) => console.warn('Oops', error)
        );
    };

    return (
        <Modal
            onClose={() => setVisibility(false)}
            isShown={isVisible}
            size="900px"
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ overflowY: 'scroll' }}
                >
                    <Modal.Header
                        title={`${
                            formData && formData.id ? 'Edit' : 'Create'
                        } Coupon`}
                        onClose={() => setVisibility(false)}
                    />
                    <Modal.Content>
                        {formData && formData.id ? (
                            <FormControl>
                                <FormControl.Label>Code</FormControl.Label>
                                <TextInput
                                    isDisabled={true}
                                    {...register('code')}
                                />
                            </FormControl>
                        ) : (
                            <FormControl>
                                <FormControl.Label isRequired>
                                    How Many
                                </FormControl.Label>
                                <TextInput
                                    type="number"
                                    {...register('howMany', { required: true })}
                                />
                                {errors.howMany && (
                                    <FormControl.ValidationMessage>
                                        This field is required
                                    </FormControl.ValidationMessage>
                                )}
                            </FormControl>
                        )}
                        <FormControl>
                            <FormControl.Label isRequired>
                                Value
                            </FormControl.Label>
                            <TextInput
                                type="number"
                                isDisabled={formData && formData.id}
                                {...register('value', { required: true })}
                            />
                            {errors.value && (
                                <FormControl.ValidationMessage>
                                    This field is required
                                </FormControl.ValidationMessage>
                            )}
                        </FormControl>
                        <FormControl>
                            <FormControl.Label>Type ($ or %)</FormControl.Label>
                            <Select
                                {...register('type', { required: true })}
                                isDisabled={formData && formData.id}
                            >
                                <Select.Option value="amount">
                                    Amount
                                </Select.Option>
                                <Select.Option value="percent">
                                    Percent
                                </Select.Option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormControl.Label isRequired>
                                Max redemption for each coupon
                            </FormControl.Label>
                            <TextInput
                                type="number"
                                isDisabled={formData && formData.id}
                                {...register('maxRedemptions', {
                                    required: true,
                                })}
                            />
                            {errors.maxRedemptions && (
                                <FormControl.ValidationMessage>
                                    This field is required
                                </FormControl.ValidationMessage>
                            )}
                        </FormControl>
                        <FormControl style={{ minWidth: 120 }}>
                            <FormControl.Label>Start date</FormControl.Label>
                            <Datepicker
                                selected={
                                    formData && formData.validFromDate
                                        ? new Date(formData.validFromDate)
                                        : new Date()
                                }
                                onSelect={(val) => {
                                    const data = {
                                        ...formData,
                                        validFromDate: val,
                                    };
                                    setFormData(data);
                                }}
                                inputProps={{
                                    isDisabled: formData && formData.id,
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <Checkbox
                                isChecked={
                                    formData && formData.enableValidToDate
                                }
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        enableValidToDate: e.target.checked,
                                    });
                                }}
                            >
                                Enable end date (valid until the selected date
                                or until the max redemption is hit)
                            </Checkbox>
                        </FormControl>
                        {formData && formData.enableValidToDate == true && (
                            <FormControl style={{ minWidth: 120 }}>
                                <FormControl.Label>End Date</FormControl.Label>
                                <Datepicker
                                    selected={
                                        formData && formData.validToDate
                                            ? new Date(formData.validToDate)
                                            : new Date()
                                    }
                                    onSelect={(val) => {
                                        const data = {
                                            ...formData,
                                            validToDate: val,
                                        };
                                        setFormData(data);
                                    }}
                                    inputProps={{
                                        isDisabled:
                                            formData &&
                                            !formData.enableValidToDate,
                                    }}
                                />
                            </FormControl>
                        )}
                        <FormControl>
                            <FormControl.Label>Description</FormControl.Label>
                            <TextInput {...register('description')} />
                        </FormControl>
                        {/* Hiding it as we only apply it for Order */}
                        {/* <FormControl>
                            <FormControl.Label>Valid for:</FormControl.Label>
                            <Checkbox {...register('validForOrder')}>
                                Order
                            </Checkbox>
                            <Checkbox
                                isDisabled={true}
                                {...register('validForSubscription')}
                            >
                                Subscription (Not available)
                            </Checkbox>
                            <Checkbox
                                isDisabled={true}
                                {...register('validForOneOff')}
                            >
                                One-Off Invoices (Not available)
                            </Checkbox>
                            <Checkbox
                                isDisabled={true}
                                {...register('validForAnonymousOneOff')}
                            >
                                Anonymous One-Off Invoices (Not available)
                            </Checkbox>
                        </FormControl> */}
                    </Modal.Content>
                    <Modal.Controls>
                        <Button
                            size="small"
                            variant="transparent"
                            onClick={() => {
                                reset();
                                setVisibility(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button size="small" variant="positive" type="submit">
                            {formData && formData.id ? 'Save' : 'Create'}
                        </Button>
                    </Modal.Controls>
                </Form>
            )}
        </Modal>
    );
};
