import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../auth/authProvider';

const PageLogin = () => {
    const navigate = useNavigate();
    const { signinRedirect, isAuthenticated } = useAuth();

    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                navigate('/dashboard');
            } else {
                setTimeout(function () {
                    signinRedirect();
                }, 1000);
            }
        })();
    }, [isAuthenticated]);

    return (
        // <Workbench>
        //     <WorkbenchHeader
        //         title="VetDesk Admin"
        //         actions={
        //             <Button
        //                 // isLoading={isLoading}
        //                 // isDisabled={isLoading}
        //                 startIcon={<LogoutIcon variant={'muted'} />}
        //                 size="small"
        //                 onClick={signinRedirect}
        //             >
        //                 Sign In
        //             </Button>
        //         }
        //     ></WorkbenchHeader>
        //     <WorkbenchSidebar position="left" className="fullWidthAside">
        //         {' '}
        //     </WorkbenchSidebar>
        //     <WorkbenchContent>
        //         Welcome to the VetDesk Admin Portal! Please sign in before
        //         proceeding.
        //     </WorkbenchContent>
        // </Workbench>
        <div>Loading...</div>
    );
};

export default PageLogin;
