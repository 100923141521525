import {
    UserManager,
    UserManagerSettings,
    WebStorageStateStore,
} from 'oidc-client';

import { ENV } from '../environments';

export const settings: UserManagerSettings = {
    authority: ENV.authUrl,
    client_id: 'vetdesk-web',
    client_secret: 'laZSvPs2k5JzP1lehbRuwzU1Y2QRfeg4',
    redirect_uri: `${ENV.selfUrl}/signin_redirect`,
    post_logout_redirect_uri: `${ENV.selfUrl}/signout_redirect`,
    silent_redirect_uri: `${ENV.selfUrl}/silent-renew`,
    response_type: 'code',
    scope: 'openid profile vetdesk',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const userManager = new UserManager(settings);

export default userManager;
