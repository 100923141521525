import React, { useEffect, useState } from 'react';

import {
    Flex,
    Heading,
    Stack,
    Tabs,
    TextInput,
} from '@contentful/f36-components';
import { SearchIcon } from '@contentful/f36-icons';

import { PaginationWrapper } from '../components/pagination-wrapper';
import { TableList } from '../components/table-list';
import { ENV } from '../environments';
import { useDebounce, useVetdeskMiddleware } from '../hooks';
import { ApiListResponse } from '../models';
import { PaymentTransaction } from '../models/paymentTransaction';
import {
    DEFAULT_FETCH_LIST_FILTERS,
    getQueryString,
    OneOffInvoiceFetchListFilters,
    OneOffInvoiceFetchType,
} from '../utilities/fetch-filters';
import {
    ANONYMOUS_ONEOFF_INVOICES_COLUMNS,
    ONEOFF_INVOICES_COLUMNS,
} from '../utilities/table-columns';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/transaction/oneoff`;

export const PageOneoffInvoices = () => {
    const { fetchEntityList } = useVetdeskMiddleware<PaymentTransaction>();
    const [paymentTransactions, setPaymentTransactions] = useState<
        ApiListResponse<PaymentTransaction> | undefined
    >(undefined);
    const [refresh, setRefresh] = useState(false);

    const [filters, setFilters] = useState<OneOffInvoiceFetchListFilters>({
        ...DEFAULT_FETCH_LIST_FILTERS,
        anonymous: false,
    });

    const [currentTab, setCurrentTab] = useState<OneOffInvoiceFetchType>(
        OneOffInvoiceFetchType.Normal
    );

    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const fetchList = () => {
        setPaymentTransactions(undefined); // to display skeleton when fetching
        fetchEntityList(`${API_URL}${getQueryString(filters)}`).then((res) => {
            setPaymentTransactions(res);
        });
    };

    useEffect(() => {
        fetchList();
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchList();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            setFilters({
                ...filters,
                search: debouncedSearchInput,
                offset: 0,
            });
        }
    }, [debouncedSearchInput]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (
            filters.anonymous !==
            (currentTab === OneOffInvoiceFetchType.Anonymous)
        ) {
            setFilters({
                ...filters,
                anonymous: currentTab == OneOffInvoiceFetchType.Anonymous,
                offset: 0,
            });
        }
    }, [currentTab]);

    const renderTable = () => {
        return (
            <>
                <TableList<PaymentTransaction>
                    columns={
                        currentTab == OneOffInvoiceFetchType.Normal
                            ? ONEOFF_INVOICES_COLUMNS
                            : ANONYMOUS_ONEOFF_INVOICES_COLUMNS
                    }
                    items={paymentTransactions?.items}
                />
                {paymentTransactions && (
                    <PaginationWrapper
                        onPageChange={handlePageChange}
                        filters={filters}
                        totalItems={paymentTransactions.total}
                    />
                )}
            </>
        );
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>One-off Payments</Heading>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search..."
                    icon={<SearchIcon />}
                />

                <Tabs
                    currentTab={currentTab}
                    onTabChange={(e: string | OneOffInvoiceFetchType) =>
                        setCurrentTab(e as OneOffInvoiceFetchType)
                    }
                >
                    <Tabs.List>
                        <Tabs.Tab panelId={OneOffInvoiceFetchType.Normal}>
                            Users
                        </Tabs.Tab>
                        <Tabs.Tab panelId={OneOffInvoiceFetchType.Anonymous}>
                            Anonymous
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel id={OneOffInvoiceFetchType.Normal}>
                        {renderTable()}
                    </Tabs.Panel>
                    <Tabs.Panel id={OneOffInvoiceFetchType.Anonymous}>
                        {renderTable()}
                    </Tabs.Panel>
                </Tabs>
            </Flex>
        </>
    );
};
