import React, { useEffect, useState } from 'react';

import {
    Flex,
    Heading,
    Stack,
    Tabs,
    TextInput,
} from '@contentful/f36-components';
import { SearchIcon } from '@contentful/f36-icons';

import { CustomerDetailPage } from '../components/customers/customer-detail';
import { PaginationWrapper } from '../components/pagination-wrapper';
import { TableList } from '../components/table-list';
import { ENV } from '../environments';
import { useDebounce, useVetdeskMiddleware } from '../hooks';
import { ApiListResponse } from '../models';
import { Customer } from '../models/customers';
import {
    CustomerFetchListFilters,
    CustomerFetchType,
    DEFAULT_FETCH_LIST_FILTERS,
    getQueryString,
} from '../utilities/fetch-filters';
import { CUSTOMER_COLUMNS } from '../utilities/table-columns';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/customer`;

export const PageCustomers = () => {
    const { fetchEntityList, deleteEntity } = useVetdeskMiddleware<Customer>();
    const [customers, setCustomers] = useState<
        ApiListResponse<Customer> | undefined
    >(undefined);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<CustomerFetchListFilters>({
        ...DEFAULT_FETCH_LIST_FILTERS,
        anonymous: false,
    });

    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);
    // const [showForm, setShowForm] = useState(false);
    // const [formData, setFormData] = useState(null);.

    const [showDetail, setShowDetail] = useState(false);
    const [selected, setSelected] = useState<Customer | undefined>(undefined);

    const [currentTab, setCurrentTab] = useState<CustomerFetchType>(
        CustomerFetchType.Registered
    );

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    // const handleUpsert = (formData?: any) => {
    //     setFormData(formData || null);
    //     setShowForm(true);
    // };

    // const handleDelete = (itemId: string) => {
    //     deleteEntity(API_URL, itemId).then(() => {
    //         setRefresh(true);
    //     });
    // };

    const fetchCustomers = () => {
        setCustomers(undefined); // to display skeleton when fetching
        fetchEntityList(`${API_URL}${getQueryString(filters)}`).then((res) => {
            setCustomers(res);
        });
    };

    useEffect(() => {
        fetchCustomers();
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchCustomers();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            setFilters({
                ...filters,
                search: debouncedSearchInput,
                offset: 0,
            });
        }
    }, [debouncedSearchInput]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (
            filters.anonymous !==
            (currentTab === CustomerFetchType.Anonymous)
        ) {
            setFilters({
                ...filters,
                anonymous: currentTab == CustomerFetchType.Anonymous,
                offset: 0,
            });
        }
    }, [currentTab]);

    const renderTable = () => {
        return (
            <>
                <TableList<Customer>
                    columns={CUSTOMER_COLUMNS}
                    items={customers?.items}
                    onView={(customer: Customer) => {
                        setShowDetail(true);
                        setSelected(customer);
                    }}
                    // onEdit={handleUpsert}
                    // onDelete={handleDelete}
                />
                {customers && (
                    <PaginationWrapper
                        onPageChange={handlePageChange}
                        filters={filters}
                        totalItems={customers.total}
                    />
                )}
            </>
        );
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>Customers</Heading>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search..."
                    icon={<SearchIcon />}
                />
                <Tabs
                    currentTab={currentTab}
                    onTabChange={(e: string | CustomerFetchType) =>
                        setCurrentTab(e as CustomerFetchType)
                    }
                >
                    <Tabs.List>
                        <Tabs.Tab panelId={CustomerFetchType.Registered}>
                            Registered
                        </Tabs.Tab>
                        <Tabs.Tab panelId={CustomerFetchType.Anonymous}>
                            Anonymous
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel id={CustomerFetchType.Registered}>
                        {renderTable()}
                    </Tabs.Panel>
                    <Tabs.Panel id={CustomerFetchType.Anonymous}>
                        {renderTable()}
                    </Tabs.Panel>
                </Tabs>
                {showDetail && (
                    <CustomerDetailPage
                        setVisibility={setShowDetail}
                        isVisible={showDetail}
                        formData={selected}
                        setRefresh={() => true}
                    />
                )}
            </Flex>
        </>
    );
};
