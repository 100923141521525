import React from 'react';

import { Select, Stack } from '@contentful/f36-components';

// Define the enum
export enum DateRangeEnum {
    thisMonth = 'thisMonth',
    lastMonth = 'lastMonth',
    last3Months = 'last3Months',
    last6Months = 'last6Months',
    thisYear = 'thisYear',
    lastYear = 'lastYear',
    fromStartDate = 'fromStartDate',
}

// Define a mapping object for descriptions
export const DateRangeEnumDescriptions: { [key in DateRangeEnum]: string } = {
    [DateRangeEnum.thisMonth]: 'This month',
    [DateRangeEnum.lastMonth]: 'Last month',
    [DateRangeEnum.last3Months]: 'Last 3 months',
    [DateRangeEnum.last6Months]: 'Last 6 months',
    [DateRangeEnum.thisYear]: 'This year',
    [DateRangeEnum.lastYear]: 'Last year',
    [DateRangeEnum.fromStartDate]: 'From start',
};

export interface DateRangePickerProps {
    value?: DateRangeEnum;
    onSelect: (range: DateRangeEnum) => void;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
    value,
    onSelect: onSelect,
}) => {
    // Get an array of enum values
    const dateRangeOptions = Object.keys(DateRangeEnum).map((key) => ({
        label: DateRangeEnumDescriptions[
            DateRangeEnum[key as keyof typeof DateRangeEnum]
        ],
        value: DateRangeEnum[key as keyof typeof DateRangeEnum],
    }));

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onSelect(
            DateRangeEnum[event.target.value as keyof typeof DateRangeEnum]
        );
    };

    return (
        <Stack flexDirection="column" alignItems="start">
            <Select
                value={value}
                style={{ width: '150px' }}
                onChange={handleChange}
            >
                {dateRangeOptions.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
        </Stack>
    );
};
