import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
    Badge,
    Card,
    CopyButton,
    Flex,
    formatDateAndTime,
    Modal,
    Skeleton,
    Subheading,
    Table,
    Text,
} from '@contentful/f36-components';
import { Form } from '@contentful/f36-forms';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import {
    Address,
    ApiListResponse,
    FormSubscriptionProps,
    Order,
    PaymentMethod,
    Subscription,
} from '../../models';
import {
    BadgeVariantType,
    getBadgeVariant,
} from '../../utilities/badge-helper';
import { BasketDetail } from '../basket-detail';

export const FormSubscription: React.FC<FormSubscriptionProps> = (props) => {
    const { formData, isVisible, setVisibility, setRefresh } = props;
    const {
        handleSubmit,
        //formState: { errors },
        reset,
    } = useForm();

    const { updateEntity, fetchEntityList } = useVetdeskMiddleware();

    const [history, setHistory] = useState<ApiListResponse<Order> | undefined>(
        undefined
    );

    useEffect(() => {
        reset(formData || {});
        formData &&
            formData.id &&
            fetchEntityList(
                `${ENV.middlewareBaseUrl}/api/v1/admin/subscription/${formData.id}/history`
            ).then((res) => {
                setHistory(res as ApiListResponse<Order>);
            });
    }, [formData]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (data: any) => {
        if (formData.id) {
            updateEntity(
                `${ENV.middlewareBaseUrl}/api/v1/admin/subscription`,
                formData.id,
                {
                    ...formData,
                    ...data,
                }
            ).then(
                () => {
                    reset({});
                    setRefresh(true);
                    setVisibility(false);
                },
                (error) => console.warn('Oops', error)
            );
        }
    };

    const renderAddress = (address: Address) => {
        return (
            <div>
                <strong>Address:</strong>{' '}
                {address.company ? <Text>{`${address.company}, `}</Text> : null}
                {address.street ? <Text>{`${address.street}, `}</Text> : null}
                {address.suburb ? <Text>{`${address.suburb}, `}</Text> : null}
                {address.city ? <Text>{`${address.city} `}</Text> : null}
                {address.postCode ? <Text>{`${address.postCode}`}</Text> : null}
            </div>
        );
    };

    const renderPaymentMethod = (paymentMethod: PaymentMethod) => {
        return (
            <div>
                <strong>Payment method:</strong>{' '}
                <Text>{`******${paymentMethod.cardNumber}, `}</Text>
                <Text>{`Type: ${paymentMethod.brand}, `}</Text>
                <Text>{`Exp: ${paymentMethod.expiryMonth}/${paymentMethod.expiryYear}`}</Text>
            </div>
        );
    };

    const renderSubscriptionDetail = (formData: Subscription) => {
        if (!formData || !formData.basket) return <></>;

        return (
            <>
                <Subheading>{formData.name}</Subheading>
                <Card style={{ marginBottom: 25 }}>
                    <Flex justifyContent="space-between" alignItems="center">
                        <div>
                            <strong>Number</strong> # {formData.number}
                            <div>
                                <strong>Frequency:</strong>{' '}
                                <Badge
                                    variant={getBadgeVariant(
                                        formData.interval,
                                        BadgeVariantType.SubscritptionFrequency
                                    )}
                                >
                                    {formData.interval}
                                </Badge>
                            </div>
                            <div>
                                <strong>First delivery:</strong>{' '}
                                {formatDateAndTime(
                                    formData.firstDeliveryDate,
                                    'day'
                                )}
                            </div>
                            <div>
                                <strong>Next payment:</strong>{' '}
                                {formatDateAndTime(
                                    formData.nextPaymentDate,
                                    'day'
                                )}
                            </div>
                            <div>
                                <strong>Customer:</strong> {formData.username}
                            </div>
                            <div>
                                <strong>Created date:</strong>{' '}
                                {formatDateAndTime(formData.createdDate)}
                            </div>
                        </div>
                        <div>
                            <div>
                                <strong>Status:</strong>{' '}
                                <Badge
                                    variant={getBadgeVariant(
                                        formData.status,
                                        BadgeVariantType.Subscritption
                                    )}
                                >
                                    {formData.status}
                                </Badge>
                            </div>
                            <div>
                                <strong>Stripe:</strong>{' '}
                                {formData.stripeIdentifier}
                                <CopyButton
                                    size={'small'}
                                    style={{
                                        borderRadius: '50%',
                                        marginLeft: 5,
                                    }}
                                    value={formData.stripeIdentifier}
                                    tooltipProps={{ placement: 'right' }}
                                />
                            </div>
                            {formData.address &&
                                renderAddress(formData.address)}
                            {formData.paymentMethod &&
                                renderPaymentMethod(formData.paymentMethod)}
                        </div>
                    </Flex>
                </Card>
            </>
        );
    };

    const renderHistory = () => {
        if (!history || !history.items)
            return (
                <Skeleton.Container>
                    <Skeleton.BodyText numberOfLines={4} />
                </Skeleton.Container>
            );

        return (
            <Table>
                <Table.Head>
                    <Table.Row>
                        <Table.Cell>Order #</Table.Cell>
                        <Table.Cell>Stripe invoice</Table.Cell>
                        <Table.Cell>Created On</Table.Cell>
                        <Table.Cell>Shipping</Table.Cell>
                        <Table.Cell>Total</Table.Cell>
                        <Table.Cell>Status</Table.Cell>
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {history.items.map((x, i) => (
                        <Table.Row key={i}>
                            <Table.Cell>{x.number}</Table.Cell>
                            <Table.Cell>
                                {x.paymentTransaction?.gatewayReference}
                                {x.paymentTransaction && (
                                    <CopyButton
                                        size="small"
                                        style={{
                                            borderRadius: '50%',
                                            marginLeft: 5,
                                        }}
                                        value={
                                            x.paymentTransaction
                                                ?.gatewayReference
                                        }
                                        tooltipProps={{
                                            placement: 'right',
                                        }}
                                    />
                                )}
                            </Table.Cell>
                            <Table.Cell>
                                {formatDateAndTime(x.createdDate)}
                            </Table.Cell>
                            <Table.Cell>
                                ${x.basket.shippingTotal.toFixed(2)}
                            </Table.Cell>
                            <Table.Cell>
                                ${x.totalAmount.toFixed(2)} - Inc GST of $
                                {x.basket.tax.toFixed(2)}
                            </Table.Cell>
                            <Table.Cell>
                                <Badge
                                    variant={getBadgeVariant(
                                        x.status,
                                        BadgeVariantType.Order
                                    )}
                                >
                                    {x.status}
                                </Badge>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    };

    return (
        <Modal
            onClose={() => setVisibility(false)}
            isShown={isVisible}
            size="1200px"
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ overflowY: 'scroll' }}
                >
                    <Modal.Header
                        title="Subscription detail"
                        onClose={() => setVisibility(false)}
                    />
                    <Modal.Content>
                        {formData &&
                            formData.id &&
                            renderSubscriptionDetail(formData)}
                        {formData && formData.basket && (
                            <BasketDetail basket={formData.basket} />
                        )}
                        <Subheading style={{ marginTop: 25 }}>
                            Subscription history
                        </Subheading>
                        {formData && formData.id && renderHistory()}
                    </Modal.Content>
                </Form>
            )}
        </Modal>
    );
};
