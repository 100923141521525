import React, { useEffect, useState } from 'react';

import { Card, Paragraph, Skeleton, Stack, Subheading } from '@contentful/f36-components';

import { useVetdeskMiddleware } from '../../hooks';
import { navigate, NavigationLocation } from '../../locations/page';
import { DashboardAppointments, DashboardUrl, DashboardWidgetConfig } from '../../models/dashboard';
import { DateRangeEnum, DateRangePicker } from '../date-range-picker';

export const DashboardAppointmentsPage: React.FC<DashboardWidgetConfig> = (
    props
) => {
    const { runRequest } = useVetdeskMiddleware<any>();

    const [appointmentsLoading, setAppointmentsLoading] = useState(true);
    const [appointments, setAppointments] = useState<
        DashboardAppointments[] | undefined
    >([]);
    const [appointmentRange, SetAppointmentRange] = useState<DateRangeEnum>(
        props.dateRange
    );

    // appointments
    useEffect(() => {
        setAppointmentsLoading(true);
        runRequest(
            'GET',
            `${DashboardUrl}/appointments/${appointmentRange}`
        ).then((res) => {
            setAppointmentsLoading(false);
            setAppointments(res as DashboardAppointments[]);
        });
    }, [appointmentRange]);

    return (
        <Card>
            <Stack justifyContent="space-between">
                <Subheading>Appointments</Subheading>
                <DateRangePicker
                    value={appointmentRange}
                    onSelect={(v) => SetAppointmentRange(v as DateRangeEnum)}
                />
            </Stack>
            {appointmentsLoading ? (
                <Skeleton.Container>
                    <Skeleton.BodyText numberOfLines={4} />
                </Skeleton.Container>
            ) : appointments && appointments.length > 0 ? (
                appointments.map((item, index) => {
                    return (
                        <Card
                            key={index}
                            style={{ marginTop: 15 }}
                            onClick={() =>
                                navigate(NavigationLocation.appointments)
                            }
                        >
                            <Subheading>{item.clinicName}</Subheading>
                            <Paragraph>
                                Pending requests{' '}
                                <b>{item.pendingAppointments}</b>
                            </Paragraph>
                            <Paragraph marginBottom="none">
                                Confirmed {item.confirmedAppointments}
                            </Paragraph>
                            <Paragraph>
                                Cancelled {item.cancelledAppointments}
                            </Paragraph>
                        </Card>
                    );
                })
            ) : (
                <Paragraph>No record found</Paragraph>
            )}
        </Card>
    );
};
