import React, { useEffect, useState } from 'react';

import {
    Card,
    Skeleton,
    Stack,
    Subheading,
    Table,
} from '@contentful/f36-components';

import { useVetdeskMiddleware } from '../../hooks';
import {
    DashboardUrl,
    DashboardWidgetConfig,
    TopSellers,
} from '../../models/dashboard';
import { DateRangeEnum, DateRangePicker } from '../date-range-picker';

export const DashboardTopSellersPage: React.FC<DashboardWidgetConfig> = (
    props
) => {
    const { runRequest } = useVetdeskMiddleware<any>();

    const [topSellersLoading, setTopSellersLoading] = useState(false);
    const [topSellers, setTopSellers] = useState<TopSellers[]>([]);
    const [topSellersRange, setTopSellersRange] = useState<DateRangeEnum>(
        props.dateRange
    );

    // top sellers
    useEffect(() => {
        setTopSellersLoading(true);
        runRequest(
            'GET',
            `${DashboardUrl}/top-sellers/${topSellersRange}`
        ).then((res) => {
            setTopSellersLoading(false);
            setTopSellers(res as TopSellers[]);
        });
    }, [topSellersRange]);

    return (
        <Card>
            <Stack justifyContent="space-between">
                <Subheading>Top Sellers</Subheading>
                <DateRangePicker
                    value={topSellersRange}
                    onSelect={(v) => setTopSellersRange(v as DateRangeEnum)}
                />
            </Stack>
            <Table style={{ marginTop: 25 }}>
                <Table.Head>
                    <Table.Row>
                        <Table.Cell>Product</Table.Cell>
                        <Table.Cell>Units Sold</Table.Cell>
                        <Table.Cell>Revenue</Table.Cell>
                        <Table.Cell>Profit</Table.Cell>
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {topSellersLoading ? (
                        <Skeleton.Row rowCount={5} columnCount={5} />
                    ) : topSellers && topSellers.length > 0 ? (
                        topSellers.map((item, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>{item.name}</Table.Cell>
                                    <Table.Cell>{item.unitSold}</Table.Cell>
                                    <Table.Cell>
                                        ${item.revenue.toFixed(2)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        ${item.profit.toFixed(2)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })
                    ) : (
                        <Table.Row>No record found</Table.Row>
                    )}
                </Table.Body>
            </Table>
        </Card>
    );
};
