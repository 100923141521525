import React, { useEffect, useState } from 'react';

import {
    Badge,
    Box,
    Button,
    Card,
    Flex,
    formatDateAndTime,
    FormControl,
    Modal,
    Paragraph,
    Stack,
    Subheading,
    Switch,
    Text,
    Textarea,
    Tooltip,
} from '@contentful/f36-components';
import {
    ClockIcon,
    EditIcon,
    InfoCircleIcon,
    PlusIcon,
} from '@contentful/f36-icons';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import { ApiAppointmentStatus, Clinic } from '../../models';
import {
    Appointment,
    AppointmentNote,
    FormAppointmentProps,
} from '../../models/appointments';
import { Pet } from '../../models/pet';
import {
    BadgeVariantType,
    getBadgeLabelForAppointment,
    getBadgeVariant,
} from '../../utilities/badge-helper';
import { formatDateToISOString } from '../../utilities/date-helper';
import { ClinicSelect } from '../clinic-select';
import { DatetimePicker } from '../datetime-picker';

const customStyle = {
    petDetailLabel: { minWidth: 75 },
    defaultLabel: { minWidth: 100 },
    defaultFormControl: { marginBottom: 0 },
    top25: { marginTop: 25 },
};

// partially updatable fields
interface EditAppointmentFields {
    date: Date;
    isNewCustomer: boolean;
    clinicId: string;
    clinicName?: string;
}

export const FormAppointmentDetail: React.FC<FormAppointmentProps> = (
    props
) => {
    const { formData, isVisible, setVisibility, setRefresh } = props;

    const { runRequest } = useVetdeskMiddleware();

    const [canApprove, setCanApprove] = useState(true);
    const [canMarkAsPending, setMarkAsPending] = useState(true);
    const [canCancel, setCanCancel] = useState(true);

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [showEditModal, setShowEditModal] = useState(false);
    const [editState, setEditState] = useState<EditAppointmentFields>({
        date: formData.date,
        isNewCustomer: formData.isNewCustomer,
        clinicId: formData.clinicId,
        clinicName: formData.clinicName,
    });

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancelReason, setCancelReason] = useState('');

    const [showNewNoteModal, setShowNewNoteModal] = useState(false);
    const [newNote, setNewNote] = useState('');

    const [showPendingModal, setShowPendingModal] = useState(false);
    const [pendingReason, setPendingReason] = useState('');

    useEffect(() => {
        if (formData && formData.status) {
            switch (formData.status) {
                case ApiAppointmentStatus.NewAppointment:
                    setCanApprove(true);
                    setCanCancel(true);
                    setMarkAsPending(true);
                    break;
                case ApiAppointmentStatus.Pending:
                    setCanApprove(true);
                    setCanCancel(true);
                    setMarkAsPending(false);
                    break;
                case ApiAppointmentStatus.Confirmed:
                    setCanApprove(false);
                    setMarkAsPending(false);
                    setCanCancel(true);
                    break;
                case ApiAppointmentStatus.CancelledByAdmin:
                case ApiAppointmentStatus.CancelledByCustomer:
                case ApiAppointmentStatus.Completed:
                default:
                    setCanApprove(false);
                    setCanCancel(false);
                    setMarkAsPending(false);
                    break;
            }
        }
    }, [formData]);

    const renderNote = (notes: AppointmentNote[]) => {
        const boxStyle =
            notes.length > 1 ? { borderBottom: '1px solid gray' } : {};
        return notes.map((item, index) => (
            <Box key={index} padding="spacingS" style={boxStyle}>
                <Paragraph style={{ whiteSpace: 'pre-line' }}>
                    {item.comment}
                </Paragraph>
                <ClockIcon style={{ verticalAlign: 'text-bottom' }} />
                <Text>
                    {' '}
                    {formatDateAndTime(item.date)} by {item.createdByEmail}
                </Text>
            </Box>
        ));
    };

    const renderEditModal = () => (
        <Modal
            isShown={showEditModal}
            onClose={() => {
                setShowEditModal(false);
            }}
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header
                        title="Edit Appointment"
                        onClose={() => {
                            setShowEditModal(false);
                        }}
                    />
                    <Modal.Content>
                        <FormControl>
                            <FormControl.Label>Clinic</FormControl.Label>
                            <ClinicSelect
                                value={formData.clinicId}
                                onSelect={(clinic: Clinic) => {
                                    setEditState({
                                        ...editState,
                                        clinicId: clinic.id,
                                        clinicName: clinic?.name,
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <FormControl.Label>
                                Date and Time:
                            </FormControl.Label>{' '}
                            <DatetimePicker
                                fromDate={new Date()}
                                value={formData.date as Date}
                                onSelect={(date: Date) => {
                                    setEditState({
                                        ...editState,
                                        date,
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <Switch
                                isChecked={editState.isNewCustomer}
                                onChange={() => {
                                    setEditState((prevState) => ({
                                        ...editState,
                                        isNewCustomer: !prevState.isNewCustomer,
                                    }));
                                }}
                            >
                                Is new customer?
                            </Switch>
                        </FormControl>
                    </Modal.Content>
                    <Modal.Controls>
                        <Button
                            size="small"
                            variant="transparent"
                            onClick={() => {
                                setShowEditModal(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            size="small"
                            variant="positive"
                            onClick={() => {
                                const payload: Appointment = {
                                    ...formData,
                                    ...editState,
                                };
                                payload.date = formatDateToISOString(
                                    payload.date as Date
                                ) as string;

                                runRequest(
                                    'PUT',
                                    `${ENV.middlewareBaseUrl}/api/v1/admin/appointment/${formData.id}`,
                                    payload
                                ).then(
                                    () => {
                                        setRefresh(true);
                                        setShowEditModal(false);
                                    },
                                    (error) => console.warn('Oops', error)
                                );
                            }}
                        >
                            {formData.status == ApiAppointmentStatus.Confirmed
                                ? 'Update Confirmed Appointment'
                                : 'Save'}
                        </Button>
                    </Modal.Controls>
                </>
            )}
        </Modal>
    );

    const renderConfirmModal = () => (
        <Modal
            isShown={showConfirmModal}
            onClose={() => {
                setShowConfirmModal(false);
            }}
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header
                        title="Are you sure you want to confirm this appointment?"
                        onClose={() => {
                            setShowConfirmModal(false);
                        }}
                    />
                    <Modal.Content>
                        <FormControl>
                            <FormControl.Label>
                                Date and Time:
                            </FormControl.Label>{' '}
                            <Text>{formatDateAndTime(formData.date)}</Text>
                        </FormControl>
                    </Modal.Content>
                    <Modal.Controls>
                        <Button
                            size="small"
                            variant="transparent"
                            onClick={() => {
                                setShowConfirmModal(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            size="small"
                            variant="positive"
                            onClick={() => {
                                runRequest(
                                    'PUT',
                                    `${ENV.middlewareBaseUrl}/api/v1/admin/appointment/${formData.id}/approve`,
                                    formatDateToISOString(
                                        new Date(formData.date)
                                    )
                                ).then(
                                    () => {
                                        setRefresh(true);
                                        setShowConfirmModal(false);
                                    },
                                    (error) => console.warn('Oops', error)
                                );
                            }}
                        >
                            Confirm Appointment
                        </Button>
                    </Modal.Controls>
                </>
            )}
        </Modal>
    );

    const renderCancelModal = () => (
        <Modal
            isShown={showCancelModal}
            onClose={() => {
                setShowCancelModal(false);
                setCancelReason('');
            }}
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header
                        title="Are you sure you want to cancel this appointment?"
                        onClose={() => {
                            setShowCancelModal(false);
                            setCancelReason('');
                        }}
                    />
                    <Modal.Content>
                        <FormControl>
                            <Text fontColor="red700">
                                IMPORTATNT: A cancellation email will be sent to
                                your customer.
                            </Text>
                        </FormControl>
                        <FormControl>
                            <FormControl.Label>
                                Reason for cancel (Required):
                            </FormControl.Label>{' '}
                            <Textarea
                                autoFocus
                                rows={4}
                                value={cancelReason}
                                onChange={(e) =>
                                    setCancelReason(e.target.value)
                                }
                            />
                        </FormControl>
                    </Modal.Content>
                    <Modal.Controls>
                        <Button
                            size="small"
                            variant="transparent"
                            onClick={() => {
                                setShowCancelModal(false);
                                setCancelReason('');
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            size="small"
                            variant="negative"
                            isDisabled={!canCancel || cancelReason.trim() == ''}
                            onClick={() => {
                                if (cancelReason.trim() != '') {
                                    runRequest(
                                        'PUT',
                                        `${ENV.middlewareBaseUrl}/api/v1/admin/appointment/${formData.id}/cancel`,
                                        { note: cancelReason }
                                    ).then(
                                        () => {
                                            setRefresh(true);
                                            setShowCancelModal(false);
                                            setCancelReason('');
                                        },
                                        (error) => console.warn('Oops', error)
                                    );
                                }
                            }}
                        >
                            Cancel Appointment
                        </Button>
                    </Modal.Controls>
                </>
            )}
        </Modal>
    );

    const renderPendingModal = () => (
        <Modal
            isShown={showPendingModal}
            onClose={() => {
                setShowPendingModal(false);
                setPendingReason('');
            }}
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header
                        title="Mark this appointment as 'Pending'?"
                        onClose={() => {
                            setShowPendingModal(false);
                            setPendingReason('');
                        }}
                    />
                    <Modal.Content>
                        <FormControl>
                            <FormControl.Label>
                                Note for internal use (Required):
                            </FormControl.Label>{' '}
                            <Textarea
                                autoFocus
                                rows={4}
                                value={pendingReason}
                                onChange={(e) =>
                                    setPendingReason(e.target.value)
                                }
                            />
                        </FormControl>
                    </Modal.Content>
                    <Modal.Controls>
                        <Button
                            size="small"
                            variant="transparent"
                            onClick={() => {
                                setShowPendingModal(false);
                                setPendingReason('');
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            size="small"
                            variant="primary"
                            isDisabled={
                                !canMarkAsPending || pendingReason.trim() == ''
                            }
                            onClick={() => {
                                if (pendingReason.trim() != '') {
                                    runRequest(
                                        'PUT',
                                        `${ENV.middlewareBaseUrl}/api/v1/admin/appointment/${formData.id}/pending`,
                                        { note: pendingReason }
                                    ).then(
                                        () => {
                                            setRefresh(true);
                                            setShowPendingModal(false);
                                            setPendingReason('');
                                        },
                                        (error) => console.warn('Oops', error)
                                    );
                                }
                            }}
                        >
                            Mark As Pending
                        </Button>
                    </Modal.Controls>
                </>
            )}
        </Modal>
    );

    const renderNewNoteModal = () => (
        <Modal
            isShown={showNewNoteModal}
            onClose={() => {
                setShowNewNoteModal(false);
                setNewNote('');
            }}
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header
                        title="Add an internal note"
                        onClose={() => {
                            setShowNewNoteModal(false);
                            setNewNote('');
                        }}
                    />
                    <Modal.Content>
                        <FormControl>
                            <FormControl.Label>
                                Note (required):
                            </FormControl.Label>{' '}
                            <Textarea
                                autoFocus
                                value={newNote}
                                rows={4}
                                onChange={(e) => setNewNote(e.target.value)}
                            />
                        </FormControl>
                    </Modal.Content>
                    <Modal.Controls>
                        <Button
                            size="small"
                            variant="transparent"
                            onClick={() => {
                                setShowNewNoteModal(false);
                                setNewNote('');
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            size="small"
                            variant="primary"
                            isDisabled={!newNote || newNote.trim() == ''}
                            onClick={() => {
                                if (newNote.trim() != '') {
                                    runRequest(
                                        'PUT',
                                        `${ENV.middlewareBaseUrl}/api/v1/admin/appointment/${formData.id}/internal-note`,
                                        { note: newNote }
                                    ).then(
                                        () => {
                                            setRefresh(true);
                                            setShowNewNoteModal(false);
                                            setNewNote('');
                                        },
                                        (error) => console.warn('Oops', error)
                                    );
                                }
                            }}
                        >
                            Add
                        </Button>
                    </Modal.Controls>
                </>
            )}
        </Modal>
    );

    const renderPetInfo = () =>
        formData.pets != null &&
        formData.pets.length > 0 && (
            <>
                <Subheading style={customStyle.top25}>
                    {formData.pets.length == 1 ? 'Pet Details' : 'Pets Details'}
                </Subheading>
                <Stack flexWrap="wrap" flexBasis="250px">
                    {formData.pets.map((pet: Pet) => {
                        return (
                            <Card key={pet.id} style={{ width: 250 }}>
                                <FormControl
                                    style={customStyle.defaultFormControl}
                                >
                                    <FormControl.Label
                                        style={customStyle.petDetailLabel}
                                    >
                                        Name:
                                    </FormControl.Label>
                                    <Text>{pet.name}</Text>
                                </FormControl>

                                {pet.type && (
                                    <FormControl
                                        style={customStyle.defaultFormControl}
                                    >
                                        <FormControl.Label
                                            style={customStyle.petDetailLabel}
                                        >
                                            Pet:
                                        </FormControl.Label>
                                        <Text>
                                            {pet.type === 'other'
                                                ? pet.otherPetType
                                                : pet.type}
                                        </Text>
                                    </FormControl>
                                )}

                                <FormControl
                                    style={customStyle.defaultFormControl}
                                >
                                    <FormControl.Label
                                        style={customStyle.petDetailLabel}
                                    >
                                        DOB:
                                    </FormControl.Label>
                                    <Text>
                                        {pet.dateOfBirth &&
                                            formatDateAndTime(
                                                pet.dateOfBirth,
                                                'day'
                                            )}
                                    </Text>
                                </FormControl>

                                <FormControl
                                    style={customStyle.defaultFormControl}
                                >
                                    <FormControl.Label
                                        style={customStyle.petDetailLabel}
                                    >
                                        Gender:
                                    </FormControl.Label>
                                    <Text>{pet.gender}</Text>
                                </FormControl>
                                <FormControl
                                    style={customStyle.defaultFormControl}
                                >
                                    <FormControl.Label
                                        style={customStyle.petDetailLabel}
                                    >
                                        Breed:
                                    </FormControl.Label>
                                    <Text> {pet.breed}</Text>
                                </FormControl>
                            </Card>
                        );
                    })}
                </Stack>
            </>
        );

    const renderInternalNoteSection = () => (
        <>
            <Subheading style={customStyle.top25}>
                Internal notes (admin only):{' '}
                <Tooltip
                    placement="top"
                    content="These internal notes are not visible to the customer."
                >
                    <InfoCircleIcon
                        id="tooltip_2"
                        style={{ verticalAlign: 'middle' }}
                        size="medium"
                    />
                </Tooltip>
            </Subheading>
            <Button
                size="small"
                variant="secondary"
                startIcon={<PlusIcon />}
                onClick={() => {
                    setShowNewNoteModal(true);
                }}
            >
                Add note
            </Button>
            <Stack flexWrap="wrap" flexBasis="300px">
                {formData.internalNotes &&
                    formData.internalNotes.length > 0 && (
                        <FormControl>
                            {renderNote(
                                formData.internalNotes as AppointmentNote[]
                            )}
                        </FormControl>
                    )}
            </Stack>
        </>
    );

    const renderCustomerDetail = () => (
        <>
            <Subheading>Customer details</Subheading>
            <Card>
                <FormControl style={customStyle.defaultFormControl}>
                    <FormControl.Label style={customStyle.defaultLabel}>
                        Name:
                    </FormControl.Label>
                    <Text>
                        {formData.firstName} {formData.lastName}
                    </Text>
                </FormControl>

                <FormControl style={customStyle.defaultFormControl}>
                    <FormControl.Label style={customStyle.defaultLabel}>
                        Phone:
                    </FormControl.Label>
                    <Text>{formData.phone}</Text>
                </FormControl>
                <FormControl style={customStyle.defaultFormControl}>
                    <FormControl.Label style={customStyle.defaultLabel}>
                        Email:
                    </FormControl.Label>
                    <Text>{formData.email}</Text>
                </FormControl>
                <FormControl style={customStyle.defaultFormControl}>
                    <FormControl.Label style={customStyle.defaultLabel}>
                        Comment:
                    </FormControl.Label>
                    <Text style={{ whiteSpace: 'pre-line' }}>
                        {formData.comment}
                    </Text>
                </FormControl>
            </Card>
        </>
    );

    const renderAppointmentDetail = () => (
        <>
            <Flex flexDirection="row" gap="spacingS" justifyContent="center">
                <div style={{ width: '50%' }}>
                    <Subheading>Appointment details</Subheading>
                    <Card>
                        <FormControl style={customStyle.defaultFormControl}>
                            <FormControl.Label style={customStyle.defaultLabel}>
                                Status:
                            </FormControl.Label>
                            <Badge
                                variant={getBadgeVariant(
                                    formData.status,
                                    BadgeVariantType.Appointment
                                )}
                            >
                                {getBadgeLabelForAppointment(formData.status)}
                            </Badge>
                        </FormControl>

                        <FormControl style={customStyle.defaultFormControl}>
                            <FormControl.Label style={customStyle.defaultLabel}>
                                Clinic:
                            </FormControl.Label>{' '}
                            <Text>{formData.clinicName}</Text>
                        </FormControl>

                        <FormControl style={customStyle.defaultFormControl}>
                            <FormControl.Label style={customStyle.defaultLabel}>
                                Date:
                            </FormControl.Label>{' '}
                            <Text>{formatDateAndTime(formData.date)}</Text>
                        </FormControl>

                        <FormControl style={customStyle.defaultFormControl}>
                            <FormControl.Label style={customStyle.defaultLabel}>
                                New customer:
                            </FormControl.Label>{' '}
                            <Badge variant="primary">
                                {formData.isNewCustomer == true ? 'Yes' : 'No'}
                            </Badge>
                        </FormControl>

                        <FormControl style={customStyle.defaultFormControl}>
                            <FormControl.Label style={customStyle.defaultLabel}>
                                {' '}
                            </FormControl.Label>{' '}
                            <Button
                                isDisabled={!canCancel}
                                style={{ marginBottom: 15 }}
                                size="small"
                                variant="secondary"
                                startIcon={<EditIcon />}
                                onClick={() => {
                                    setShowEditModal(true);
                                }}
                            >
                                Edit Appointment
                            </Button>
                        </FormControl>

                        {formData.notes && formData.notes.length > 0 && (
                            <FormControl>
                                <FormControl.Label>
                                    Cancellation Note:
                                </FormControl.Label>
                                {renderNote(
                                    formData.notes as AppointmentNote[]
                                )}
                            </FormControl>
                        )}
                    </Card>
                    {renderInternalNoteSection()}
                </div>
                <div style={{ width: '50%' }}>
                    {renderCustomerDetail()}
                    {renderPetInfo()}
                </div>
            </Flex>
        </>
    );

    return (
        <>
            <Modal
                onClose={() => {
                    setVisibility(false);
                }}
                isShown={isVisible}
                size="1200px"
                shouldCloseOnOverlayClick={false}
            >
                {() =>
                    formData && (
                        <>
                            <Modal.Header
                                title={'Manage Appointment'}
                                onClose={() => setVisibility(false)}
                            />
                            <Modal.Content>
                                {renderAppointmentDetail()}
                            </Modal.Content>
                            <Modal.Controls>
                                <Button
                                    size="small"
                                    variant="transparent"
                                    onClick={() => {
                                        setVisibility(false);
                                    }}
                                >
                                    Close
                                </Button>

                                <Button
                                    size="small"
                                    variant="primary"
                                    isDisabled={!canMarkAsPending}
                                    onClick={() => {
                                        setShowPendingModal(true);
                                    }}
                                >
                                    Pending Appointment
                                </Button>

                                <Button
                                    size="small"
                                    variant="negative"
                                    isDisabled={!canCancel}
                                    onClick={() => {
                                        setShowCancelModal(true);
                                    }}
                                >
                                    Cancel Appointment
                                </Button>
                                <Button
                                    size="small"
                                    variant="positive"
                                    isDisabled={!canApprove}
                                    onClick={() => {
                                        setShowConfirmModal(true);
                                    }}
                                >
                                    Confirm Appointment
                                </Button>
                            </Modal.Controls>
                        </>
                    )
                }
            </Modal>
            {renderCancelModal()}
            {renderNewNoteModal()}
            {renderConfirmModal()}
            {renderEditModal()}
            {renderPendingModal()}
        </>
    );
};
