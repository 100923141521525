import React, { useEffect, useState } from 'react';

import {
    Flex,
    Heading,
    Stack,
    Tabs,
    TextInput,
} from '@contentful/f36-components';
import { SearchIcon } from '@contentful/f36-icons';

import { FormOrder } from '../components/forms/form-order';
import { PaginationWrapper } from '../components/pagination-wrapper';
import { TableList } from '../components/table-list';
import { ENV } from '../environments';
import { useDebounce, useVetdeskMiddleware } from '../hooks';
import { ApiListResponse, Order } from '../models';
import {
    DEFAULT_FETCH_LIST_FILTERS,
    getQueryString,
    OrderFetchListFilters,
    OrderFetchType,
} from '../utilities/fetch-filters';
import {
    FUNDRAISER_ORDER_COLUMNS,
    ORDER_COLUMNS,
} from '../utilities/table-columns';

const ORDER_API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/order`;
const FUNDRAISER_API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/donation`;

export const PageOrders = () => {
    const { fetchEntityList } = useVetdeskMiddleware<Order>();
    const [orders, setOrders] = useState<ApiListResponse<Order> | undefined>(
        undefined
    );
    const [refresh, setRefresh] = useState(false);

    const [filters, setFilters] = useState<OrderFetchListFilters>({
        ...DEFAULT_FETCH_LIST_FILTERS,
        anonymous: false,
        isFundraiser: false,
    });

    const [currentTab, setCurrentTab] = useState<OrderFetchType>(
        OrderFetchType.Normal
    );

    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState(null);

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const handleUpsert = (formData?: any) => {
        setFormData(formData || null);
        setShowForm(true);
    };

    const fetchOrders = () => {
        setOrders(undefined); // to display skeleton when fetching
        fetchEntityList(
            `${
                currentTab === OrderFetchType.Fundraiser
                    ? FUNDRAISER_API_URL
                    : ORDER_API_URL
            }${getQueryString(filters)}`
        ).then((res) => {
            setOrders(res);
        });
    };

    useEffect(() => {
        fetchOrders();
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchOrders();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            setFilters({
                ...filters,
                search: debouncedSearchInput,
                offset: 0,
            });
        }
    }, [debouncedSearchInput]);

    useEffect(() => {
        let updatedFilters: OrderFetchListFilters;

        switch (currentTab) {
            case OrderFetchType.Normal:
                updatedFilters = {
                    ...filters,
                    anonymous: false,
                    isFundraiser: false,
                };
                break;
            case OrderFetchType.Anonymous:
                updatedFilters = {
                    ...filters,
                    anonymous: true,
                    isFundraiser: false,
                };
                break;
            case OrderFetchType.Fundraiser:
                updatedFilters = {
                    ...filters,
                    anonymous: undefined,
                    isFundraiser: true,
                };
                // remove anonymous filter to fetch all data
                delete updatedFilters.anonymous;
                break;
            default:
                updatedFilters = {
                    ...filters,
                };
                break;
        }

        // if statement to avoid calling the fetch endpoint twice during mounting
        if (
            filters.anonymous !== updatedFilters.anonymous ||
            filters.isFundraiser !== updatedFilters.isFundraiser
        ) {
            setFilters({
                ...updatedFilters,
                offset: 0,
            });
        }
    }, [currentTab]);

    const renderTable = () => {
        return (
            <>
                <TableList<Order>
                    columns={
                        currentTab !== OrderFetchType.Fundraiser
                            ? ORDER_COLUMNS
                            : FUNDRAISER_ORDER_COLUMNS
                    }
                    items={orders?.items}
                    onView={handleUpsert}
                    // onDelete={handleDelete}
                />
                {orders && (
                    <PaginationWrapper
                        onPageChange={handlePageChange}
                        filters={filters}
                        totalItems={orders.total}
                    />
                )}
            </>
        );
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>Orders</Heading>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search..."
                    icon={<SearchIcon />}
                />
                <Tabs
                    currentTab={currentTab}
                    onTabChange={(e: string | OrderFetchType) =>
                        setCurrentTab(e as OrderFetchType)
                    }
                >
                    <Tabs.List>
                        <Tabs.Tab panelId={OrderFetchType.Normal}>
                            Users
                        </Tabs.Tab>
                        <Tabs.Tab panelId={OrderFetchType.Anonymous}>
                            Anonymous
                        </Tabs.Tab>
                        <Tabs.Tab panelId={OrderFetchType.Fundraiser}>
                            Fundraiser
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel id={OrderFetchType.Normal}>
                        {renderTable()}
                    </Tabs.Panel>
                    <Tabs.Panel id={OrderFetchType.Anonymous}>
                        {renderTable()}
                    </Tabs.Panel>
                    <Tabs.Panel id={OrderFetchType.Fundraiser}>
                        {renderTable()}
                    </Tabs.Panel>
                </Tabs>
            </Flex>
            <FormOrder
                formData={formData}
                setRefresh={setRefresh}
                setVisibility={setShowForm}
                isVisible={showForm}
                isFundraiser={currentTab === OrderFetchType.Fundraiser}
            />
        </>
    );
};
