export interface ModalFormProps {
    setRefresh: (refresh: boolean) => void;
    setVisibility: (isVisible: boolean) => void;
    isVisible: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData: any;
}

export enum ApiEntityStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum ApiOrderEntityStatus {
    PROCESSING = 'processing',
    COMPLETE = 'complete',
    CANCELLED = 'cancelled',
}

export enum ApiOrderEntityTrackingStatus {
    PREPARING = 'preparing',
    PROCESSING = 'processing',
    INTRANSIT = 'inTransit',
    DELIVERED = 'delivered',
}

export enum ApiSubscriptionEntityStatus {
    ACTIVE = 'active',
    SUSPENDED = 'suspended',
    CANCELLED = 'cancelled',
    PENDING = 'pending',
}

export enum ApiSubscriptionFrequencyStatus {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    YEARLY = 'yearly',
}

export enum ApiPhotoCompetitionStatus {
    INACTIVE = 'inactive',
    ENDED = 'ended',
    RUNNING = 'running',
    PLANNED = 'planned',
}

export enum ApiAppointmentStatus {
    NewAppointment = 'newAppointment',
    Pending = 'pending',
    Confirmed = 'confirmed',
    CancelledByAdmin = 'cancelledByAdmin',
    CancelledByCustomer = 'cancelledByCustomer',
    Completed = 'completed',
}

export enum ApiNotificationStatus {
    QUEUED = 'queued',
    SENT = 'sent',
    FAILED = 'failed ',
}

export enum ApiReportedEntryStatus {
    NONE = 'none',
    PENDING = 'pending',
    SAFE = 'safe',
    INAPPROPRIATE = 'inappropriate',
}
