import React, { useEffect, useState } from 'react';

import {
    Button,
    Flex,
    Heading,
    Stack,
    TextInput,
} from '@contentful/f36-components';
import { PlusIcon, SearchIcon } from '@contentful/f36-icons';

import { FormPhotoCompetition } from '../components/forms/form-photo-competition';
import { PaginationWrapper } from '../components/pagination-wrapper';
import { PhotoCompetitionDetail } from '../components/photo-competition-detail';
import { TableList } from '../components/table-list';
import { ENV } from '../environments';
import { useDebounce, useVetdeskMiddleware } from '../hooks';
import { ApiListResponse } from '../models';
import { PhotoCompetition } from '../models/photoCompetitions';
import {
    DEFAULT_FETCH_LIST_FILTERS,
    FetchListFilters,
    getQueryString,
} from '../utilities/fetch-filters';
import { PHOTO_COMPETITION_EVENT_COLUMNS } from '../utilities/table-columns';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/`;

export const PagePhotoCompetitions = () => {
    const { fetchEntityList } = useVetdeskMiddleware();
    const [photoCompetitions, setPhotoCompetitions] = useState<
        ApiListResponse<PhotoCompetition> | undefined
    >(undefined);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<FetchListFilters>(
        DEFAULT_FETCH_LIST_FILTERS
    );

    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState(null);

    const [showDetail, setShowDetail] = useState(false);

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const handleUpsert = (formData?: any) => {
        setFormData(formData || null);
        setShowForm(true);
    };

    const fetchPhotoCompetitions = () => {
        setPhotoCompetitions(undefined); // to display skeleton when fetching
        fetchEntityList(`${API_URL}event/${getQueryString(filters)}`).then(
            (res) => {
                setPhotoCompetitions(res as ApiListResponse<PhotoCompetition>);
            }
        );
    };

    const handleViewDetail = (formData?: any) => {
        setFormData(formData || null);
        setShowDetail(true);
    };

    useEffect(() => {
        fetchPhotoCompetitions();
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchPhotoCompetitions();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            setFilters({
                ...filters,
                search: debouncedSearchInput,
                offset: 0,
            });
        }
    }, [debouncedSearchInput]);

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>Photo Competitions</Heading>
                <Button
                    startIcon={<PlusIcon />}
                    variant="primary"
                    size="medium"
                    className="addButton"
                    onClick={() => handleUpsert()}
                >
                    Add New
                </Button>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search..."
                    icon={<SearchIcon />}
                />
                <TableList<PhotoCompetition>
                    columns={PHOTO_COMPETITION_EVENT_COLUMNS}
                    items={photoCompetitions?.items}
                    onEdit={handleUpsert}
                    onView={handleViewDetail}
                />
                {photoCompetitions && (
                    <PaginationWrapper
                        onPageChange={handlePageChange}
                        filters={filters}
                        totalItems={photoCompetitions.total}
                    />
                )}
            </Flex>
            {showForm && (
                <FormPhotoCompetition
                    formData={formData}
                    setRefresh={setRefresh}
                    setVisibility={setShowForm}
                    isVisible={showForm}
                    setFormData={setFormData}
                />
            )}
            {showDetail && (
                <PhotoCompetitionDetail
                    setVisibility={setShowDetail}
                    isVisible={showDetail}
                    formData={formData}
                    setRefresh={() => true}
                />
            )}
        </>
    );
};
