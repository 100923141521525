import { Profile, User } from 'oidc-client';
import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';

import userManager from './authConfig';

// Define the shape of the context state
interface AuthContextType {
    //user: User | null;
    isAuthenticated: boolean;
    profile: Profile | null;
    accessToken: string | null;
    signinRedirect: () => void;
    signoutRedirect: () => void;
}

// Create the Auth context with default values
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define the provider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        // Get the current user from the user manager
        userManager.getUser().then(setUser);

        // Set up event listeners for user changes
        userManager.events.addUserLoaded(setUser);
        userManager.events.addUserUnloaded(() => setUser(null));

        // Clean up event listeners on component unmount
        return () => {
            userManager.events.removeUserLoaded(setUser);
            userManager.events.removeUserUnloaded(() => setUser(null));
        };
    }, []);

    // Determine isAuthenticated based on user presence
    const isAuthenticated = !!user;

    const accessToken = isAuthenticated ? user.access_token : null;

    const profile = isAuthenticated ? user.profile : null;

    // Provide the context values to the children
    return (
        <AuthContext.Provider
            value={{
                //user,
                profile,
                accessToken,
                isAuthenticated,
                signinRedirect: () => userManager.signinRedirect(),
                signoutRedirect: () => userManager.signoutRedirect(),
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the Auth context
export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
