import React, { useEffect, useState } from 'react';
import {
    CartesianGrid,
    Cell,
    Line,
    LineChart,
    Pie,
    PieChart,
    Tooltip as RechartTooltip,
    XAxis,
    YAxis,
} from 'recharts';

import {
    Box,
    Card,
    Skeleton,
    Stack,
    Subheading,
} from '@contentful/f36-components';

import { useVetdeskMiddleware } from '../../hooks';
import {
    AppDownloads,
    DashboardUrl,
    DashboardWidgetConfig,
} from '../../models/dashboard';
import { DateRangeEnum, DateRangePicker } from '../date-range-picker';

export const DashboardAppDownloadsPage: React.FC<DashboardWidgetConfig> = (
    props
) => {
    const { runRequest } = useVetdeskMiddleware<any>();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<AppDownloads | undefined>(undefined);
    const [range, setRange] = useState<DateRangeEnum>(props.dateRange);

    useEffect(() => {
        setLoading(true);
        runRequest('GET', `${DashboardUrl}/app-downloads/${range}`).then(
            (res) => {
                setLoading(false);
                setData(res as AppDownloads);
            }
        );
    }, [range]);

    // Convert to the desired format
    const transformData = (downloadData: AppDownloads) => {
        const simplifiedData: {
            year: number;
            month: number;
            ios: number;
            android?: number;
        }[] = [];

        // First populate with IOS
        Object.entries(downloadData.ios).forEach(([year, yearData]) => {
            for (const month in yearData) {
                // eslint-disable-next-line no-prototype-builtins
                if (yearData.hasOwnProperty(month)) {
                    simplifiedData.push({
                        year: parseInt(year),
                        month: parseInt(month),
                        ios: yearData[month],
                    });
                }
            }
        });

        Object.entries(downloadData.android).forEach(([year, yearData]) => {
            for (const month in yearData) {
                // eslint-disable-next-line no-prototype-builtins
                if (yearData.hasOwnProperty(month)) {
                    const objectToUpdate = simplifiedData.find(
                        (obj) =>
                            obj.year === parseInt(year) &&
                            obj.month === parseInt(month)
                    );
                    if (objectToUpdate) {
                        objectToUpdate.android = yearData[month];
                    }
                }
            }
        });

        simplifiedData.sort((a, b) => {
            // Sort by year in descending order
            if (a.year !== b.year) {
                return b.year - a.year;
            }
            // If years are equal, sort by month in ascending order
            return a.month - b.month;
        });

        const transformedData = simplifiedData.map((obj) => ({
            ...obj,
            year: obj.year.toString(),
            month: `${obj.year}-${getMonthAbbreviation(obj.month)}`,
        }));

        return transformedData;
    };

    // Convert to the desired format
    const transformDataForPie = (data: AppDownloads) => {
        const transformedData: any = [
            {
                name: 'IOS',
                value: data.iosTotal,
                color: '#8073b5',
            },
            {
                name: 'Android',
                value: data.androidTotal,
                color: '#1d4c84',
            },
        ];

        return transformedData;
    };

    const getMonthAbbreviation = (monthNumber: number): string => {
        // Ensure monthNumber is within range 1-12
        if (monthNumber < 1 || monthNumber > 12) {
            throw new Error('Month number must be between 1 and 12');
        }
        // Create a date object with the specified month
        const date = new Date(2000, monthNumber - 1, 1);

        // Get the abbreviated month name using toLocaleString()
        const monthAbbreviation = date.toLocaleString('en', { month: 'short' });

        return monthAbbreviation;
    };

    return (
        <>
            <Box style={{ marginTop: 25, width: '25%' }}>
                {data && (
                    <Card isLoading={loading}>
                        <Subheading style={{ marginBottom: 40 }}>
                            App Downloads: {data.iosTotal + data.androidTotal}
                        </Subheading>
                        <PieChart width={300} height={300}>
                            <Pie
                                data={transformDataForPie(data)}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                innerRadius={30}
                                outerRadius={70}
                                label={({ name, value }) => `${name}: ${value}`}
                            >
                                {transformDataForPie(data).map(
                                    (entry: any, index: any) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={entry.color}
                                        />
                                    )
                                )}
                            </Pie>
                        </PieChart>
                    </Card>
                )}
            </Box>
            <Box style={{ marginTop: 25, width: '75%' }}>
                {data && (
                    <Card>
                        <Stack
                            justifyContent="space-between"
                            style={{ marginBottom: 25 }}
                        >
                            <Subheading> </Subheading>
                            <DateRangePicker
                                value={range}
                                onSelect={(v) => setRange(v as DateRangeEnum)}
                            />
                        </Stack>
                        {loading ? (
                            <Skeleton.Container>
                                <Skeleton.BodyText numberOfLines={4} />
                            </Skeleton.Container>
                        ) : (
                            <LineChart
                                width={900}
                                height={300}
                                data={transformData(data)}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" interval={2} />
                                <YAxis />
                                <RechartTooltip />

                                <Line
                                    type="monotone"
                                    dataKey="ios"
                                    stroke="#8884d8"
                                    name="IOS"
                                />
                                <Line
                                    type="monotone"
                                    dataKey="android"
                                    stroke="#82ca9d"
                                    name="Android"
                                />
                            </LineChart>
                        )}
                    </Card>
                )}
            </Box>
        </>
    );
};
