import React from 'react';

import { Asset, Table } from '@contentful/f36-components';

import { Basket } from '../models/basket';

export interface BasketDetailProps {
    basket: Basket;
    coupon?: string;
    discountedTotal?: number | undefined;
    taxAfterDiscount?: number | undefined;
    isFundraiser?: boolean;
}

export const BasketDetail: React.FC<BasketDetailProps> = (props) => {
    const { basket, coupon, discountedTotal, taxAfterDiscount, isFundraiser } =
        props;

    const total = `${basket.total.toFixed(2)} - Inc GST of ${basket.tax.toFixed(
        2
    )}`;

    const totalContent = coupon ? (
        <>
            <s>{total}</s>
            <p>{`${discountedTotal?.toFixed(
                2
            )} - Inc GST of ${taxAfterDiscount?.toFixed(2)}`}</p>
        </>
    ) : (
        <>{total}</>
    );

    return (
        <Table>
            <Table.Head>
                <Table.Row>
                    {!isFundraiser && <Table.Cell></Table.Cell>}
                    <Table.Cell>Product</Table.Cell>
                    <Table.Cell>Quantity</Table.Cell>
                    <Table.Cell>Price</Table.Cell>
                    <Table.Cell>Total</Table.Cell>
                </Table.Row>
            </Table.Head>
            <Table.Body>
                <>
                    {basket.basketItems.map((x, i) => (
                        <Table.Row key={i}>
                            {!isFundraiser && (
                                <Table.Cell>
                                    <Asset
                                        src={x.productImages[0]}
                                        style={{ maxWidth: 100 }}
                                    />
                                </Table.Cell>
                            )}
                            <Table.Cell>{x.productName}</Table.Cell>
                            <Table.Cell>{x.quantity}</Table.Cell>
                            <Table.Cell>
                                {x.discountedPrice ? (
                                    <>
                                        <s>${x.price.toFixed(2)}</s> $
                                        {x.discountedPrice.toFixed(2)}
                                    </>
                                ) : (
                                    <>${x.price.toFixed(2)}</>
                                )}
                            </Table.Cell>
                            <Table.Cell>${x.itemTotal.toFixed(2)}</Table.Cell>
                        </Table.Row>
                    ))}
                    {!isFundraiser && (
                        <Table.Row key="w">
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell>
                                <strong>Sub total</strong>
                            </Table.Cell>
                            <Table.Cell>
                                ${basket.subTotal.toFixed(2)}
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {!isFundraiser && (
                        <Table.Row key="x">
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell>
                                <strong>Shipping cost</strong>
                            </Table.Cell>
                            <Table.Cell>
                                ${basket.shippingTotal.toFixed(2)}
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {!isFundraiser && coupon && (
                        <Table.Row key="coupon">
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell>
                                <strong>Coupon</strong>
                            </Table.Cell>
                            <Table.Cell>{coupon}</Table.Cell>
                        </Table.Row>
                    )}
                    <Table.Row key="y">
                        {!isFundraiser && <Table.Cell></Table.Cell>}
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>
                            <strong>Total</strong>
                        </Table.Cell>
                        <Table.Cell>
                            {!isFundraiser
                                ? totalContent
                                : `$${basket.total.toFixed(2)}`}
                        </Table.Cell>
                    </Table.Row>
                </>
            </Table.Body>
        </Table>
    );
};
