import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Modal } from '@contentful/f36-components';
import { Form, FormControl, TextInput } from '@contentful/f36-forms';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import { FormCategoryProps } from '../../models';

export const FormCategory: React.FC<FormCategoryProps> = (props) => {
    const { formData, isVisible, setVisibility, setRefresh } = props;
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const { createEntity, updateEntity } = useVetdeskMiddleware();

    useEffect(() => {
        reset(formData || {});
    }, [formData]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (data: any) => {
        (formData && formData.id
            ? updateEntity(
                  `${ENV.middlewareBaseUrl}/api/v1/admin/category`,
                  formData.id,
                  {
                      ...formData,
                      ...data,
                  }
              )
            : createEntity(`${ENV.middlewareBaseUrl}/api/v1/admin/category`, {
                  ...data,
              })
        ).then(
            () => {
                reset({});
                setRefresh(true);
                setVisibility(false);
            },
            (error) => console.warn('Oops', error)
        );
    };

    return (
        <Modal
            onClose={() => setVisibility(false)}
            isShown={isVisible}
            size="900px"
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header
                        title={`${
                            formData && formData.id ? 'Edit' : 'Add New'
                        } Category`}
                        onClose={() => setVisibility(false)}
                    />
                    <Modal.Content>
                        <FormControl isInvalid={Boolean(errors.name)}>
                            <FormControl.Label isRequired>
                                Name
                            </FormControl.Label>
                            <TextInput
                                {...register('name', { required: true })}
                            />
                            {errors.name && (
                                <FormControl.ValidationMessage>
                                    This field is required
                                </FormControl.ValidationMessage>
                            )}
                        </FormControl>
                    </Modal.Content>
                    <Modal.Controls>
                        <Button
                            size="small"
                            variant="transparent"
                            onClick={() => {
                                reset();
                                setVisibility(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button size="small" variant="positive" type="submit">
                            {formData && formData.id ? 'Save' : 'Create'}
                        </Button>
                    </Modal.Controls>
                </Form>
            )}
        </Modal>
    );
};
