import React, { useEffect } from 'react';

import { FormControl, Select } from '@contentful/f36-components';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import { SupplierCategory } from '../../models';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/supplier`;

export interface CategoryPickerSupplierProps {
    supplierId: string;
    onCategorySelect: (supplier: SupplierCategory | undefined) => void;
}

export const CategoryPickerSupplier: React.FC<CategoryPickerSupplierProps> = ({
    supplierId,
    onCategorySelect,
}) => {
    const [categories, setCategories] = React.useState<SupplierCategory[]>([]);

    const { fetchEntityList } = useVetdeskMiddleware<SupplierCategory>();

    useEffect(() => {
        fetchEntityList(`${API_URL}/${supplierId}/category`).then((res) => {
            setCategories(res.items);
        });
    }, []);

    return (
        <FormControl>
            <Select
                style={{ width: '350px' }}
                id="supplier"
                name="supplier"
                defaultValue=""
                onChange={(event) =>
                    onCategorySelect(
                        categories.find(
                            (c) => c.id.toString() === event.target.value
                        )
                    )
                }
            >
                <Select.Option value="" isDisabled>
                    Filter by category
                </Select.Option>
                {categories &&
                    categories.map((cat) => (
                        <Select.Option key={cat.id} value={cat.id}>
                            {cat.name}
                        </Select.Option>
                    ))}
            </Select>
        </FormControl>
    );
};
