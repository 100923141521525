import React, { useEffect, useState } from 'react';

import {
    Button,
    Flex,
    Heading,
    Stack,
    TextInput,
} from '@contentful/f36-components';
import { PlusIcon, SearchIcon } from '@contentful/f36-icons';

import { FormNotification } from '../components/forms/form-notification';
import { PaginationWrapper } from '../components/pagination-wrapper';
import { TableList } from '../components/table-list';
import { ENV } from '../environments';
import { useDebounce, useVetdeskMiddleware } from '../hooks';
import { ApiListResponse } from '../models';
import {
    Notification,
    NotificationRegistration,
} from '../models/notifications';
import {
    DEFAULT_FETCH_LIST_FILTERS,
    FetchListFilters,
    getQueryString,
} from '../utilities/fetch-filters';
import { NOTIFICATION_COLUMNS } from '../utilities/table-columns';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/notification`;

export const PageNotifications = () => {
    const { fetchEntityList, runRequest } = useVetdeskMiddleware();
    const [notifications, setNotifications] = useState<
        ApiListResponse<Notification> | undefined
    >(undefined);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<FetchListFilters>(
        DEFAULT_FETCH_LIST_FILTERS
    );
    const [availableRecipients, setAvailableRecipients] = useState<
        NotificationRegistration[]
    >([]);

    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState(null);

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const handleUpsert = (formData?: any) => {
        setFormData(formData || null);
        setShowForm(true);
    };

    const fetchNotifications = () => {
        setNotifications(undefined); // to display skeleton when fetching
        fetchEntityList(`${API_URL}${getQueryString(filters)}`).then((res) => {
            setNotifications(res as ApiListResponse<Notification>);
        });
    };

    const fetchRecipients = () => {
        runRequest('GET', `${API_URL}/registration/all`).then(
            (res) => {
                setAvailableRecipients(res as NotificationRegistration[]);
            },
            (error) => console.warn('Oops', error)
        );
    };

    useEffect(() => {
        fetchNotifications();
        fetchRecipients();
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchNotifications();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            setFilters({
                ...filters,
                search: debouncedSearchInput,
                offset: 0,
            });
        }
    }, [debouncedSearchInput]);

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>Notifications</Heading>
                <Button
                    startIcon={<PlusIcon />}
                    variant="primary"
                    size="medium"
                    className="addButton"
                    onClick={() => handleUpsert()}
                >
                    Add New
                </Button>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search..."
                    icon={<SearchIcon />}
                />
                <TableList<Notification>
                    columns={NOTIFICATION_COLUMNS}
                    items={notifications?.items}
                    onEdit={handleUpsert}
                />
                {notifications && (
                    <PaginationWrapper
                        onPageChange={handlePageChange}
                        filters={filters}
                        totalItems={notifications.total}
                    />
                )}
                {showForm && (
                    <FormNotification
                        formData={formData}
                        setRefresh={setRefresh}
                        setVisibility={setShowForm}
                        isVisible={showForm}
                        setFormData={setFormData}
                        availableRecipients={availableRecipients}
                    />
                )}
            </Flex>
        </>
    );
};
