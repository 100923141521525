import { ApiBaseEntity } from './api';
import { ApiEntityStatus, ModalFormProps } from './primitives';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormProductProps {
    formData: Product[];
    onSubmitted?: () => void;
}

export interface FormEditProductProps extends ModalFormProps {
    formData: Product;
    onSubmitted?: () => void;
}

export interface SupplierProductMetadata {
    supplierId: string;
    supplierName?: string;
    supplierSku: string;
    supplierProductIdentifier: string;
    supplierPrice: number;
    supplierPriceWithTax: number;
    images?: ProductImage[];
    imageUrls?: string[];
    inStock?: boolean;
}

export interface ProductImage {
    id: string;
    url: string;
    isDefault?: boolean;
}

export interface VetProductCategory {
    id: string;
    name: string;
}

export interface ProductTag {
    id: string;
    name: string;
}

export interface ProductTagCollection {
    categories: ProductTag[];
    brands: ProductTag[];
    petTypes: ProductTag[];
}

export enum TagTypeEnums {
    BRANDS = 'brands',
    CATEGORIES = 'categories',
    PETTYPES = 'pettypes',
}

export interface Product extends ApiBaseEntity, SupplierProductMetadata {
    name: string;
    status: ApiEntityStatus;
    vetId: string;
    vetName?: string;

    categories: ProductTag[];
    brands: ProductTag[];
    petTypes: ProductTag[];

    price: number;
    marginAmount: number;
    marginPercent: number;
    isDiscounted: boolean;
    discountedPrice: number | null;
    requiresPriceAdjustment?: boolean;
    selectable: boolean;
    keywords?: string;
}

export interface BulkEditProduct {
    productIds: string[];
    categories: VetProductCategory[] | null;
    marginPercent: number | null;
    discountedPrice: number | null;
    keywords?: string;
}
