import React from 'react';

import { Modal } from '@contentful/f36-components';

import { FormEditProductProps } from '../models';
import { FormProduct } from './forms/form-product';

export const EditProductsModal: React.FC<FormEditProductProps> = (props) => {
    const { formData, isVisible, setVisibility, setRefresh } = props;

    const handleCancel = () => {
        setVisibility(false);
    };

    const handleSuccessfulSubmit = () => {
        console.log('Successful submit');
        setVisibility(false);
        setRefresh(true);
    };

    /* Pass react-hook-form’s handleSubmit to the onSubmit prop of the Form component  */
    return (
        <Modal
            onClose={() => setVisibility(false)}
            isShown={isVisible}
            size="fullWidth"
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header title="Edit Product" onClose={handleCancel} />
                    <Modal.Content>
                        <FormProduct
                            formData={formData ? [formData] : []}
                            onSubmitted={handleSuccessfulSubmit}
                        />
                    </Modal.Content>
                </>
            )}
        </Modal>
    );
};
