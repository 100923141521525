import React, { useState } from 'react';

import { Button, Flex, Modal } from '@contentful/f36-components';
import { CheckCircleIcon } from '@contentful/f36-icons';

import {
    ApiEntityStatus,
    FormCategoryProps,
    Product,
    Supplier,
    SupplierProduct,
} from '../models';
import { FormProduct } from './forms/form-product';
import { ProductPicker } from './suppliers/product-picker';
import { SupplierPicker } from './suppliers/supplier-picker';

export const SupplierProductsModal: React.FC<FormCategoryProps> = (props) => {
    const { formData, isVisible, setVisibility, setRefresh } = props;

    const [supplier, setSupplier] = useState<Supplier | undefined>(undefined);
    const [selectedSupplierProducts, setSelectedSupplierProducts] = useState<
        Product[]
    >([]);
    const [showForm, setShowForm] = useState<boolean>(false);

    const handleCancel = () => {
        setSupplier(undefined);
        setSelectedSupplierProducts([]);
        setShowForm(false);
        setVisibility(false);
    };

    const handleSuccessfulSubmit = () => {
        setRefresh(true);
        handleCancel();
    };

    const handleSupplierProductSelect = (products: SupplierProduct[]) => {
        const vetProducts: Product[] = products.map((p) => ({
            id: '',
            supplierId: supplier?.id || '',
            supplierName: '',
            supplierSku: p.sku,
            supplierProductIdentifier: p.identifier,
            supplierPrice: p.price,
            supplierPriceWithTax: p.priceWithTax,
            name: p.title,
            status: ApiEntityStatus.INACTIVE,
            vetId: '',

            categories: [],
            brands: [],
            petTypes: [],
            isDiscounted: true,
            discountedPrice: null,
            price: parseFloat((p.price + p.price * 0.15).toFixed(2)),
            marginAmount: 0,
            marginPercent: 0,
            imageUrls:
                p.images && p.images.length > 0
                    ? p.images.map((x) => x.url)
                    : [],
            selectable: p.selectable,
        }));

        setSelectedSupplierProducts(vetProducts);
    };

    /* Pass react-hook-form’s handleSubmit to the onSubmit prop of the Form component  */
    return (
        <Modal
            onClose={() => setVisibility(false)}
            isShown={isVisible}
            size="fullWidth"
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header
                        title={`${
                            formData && formData.id ? 'Edit' : 'Add New'
                        } Product - ${showForm ? '2 of 2' : '1 of 2'}`}
                        onClose={handleCancel}
                    />
                    <Modal.Content>
                        {!showForm && (
                            <>
                                <SupplierPicker
                                    onSupplierSelect={(s) => {
                                        setSupplier(s);
                                    }}
                                />
                                {supplier && (
                                    <ProductPicker
                                        supplierId={supplier.id}
                                        onProductSelect={
                                            handleSupplierProductSelect
                                        }
                                    />
                                )}
                                {selectedSupplierProducts &&
                                    selectedSupplierProducts.length > 0 && (
                                        <Flex fullWidth justifyContent="center">
                                            <Button
                                                startIcon={<CheckCircleIcon />}
                                                variant="primary"
                                                onClick={() =>
                                                    setShowForm(true)
                                                }
                                            >
                                                Add{' '}
                                                {
                                                    selectedSupplierProducts.length
                                                }{' '}
                                                product
                                                {selectedSupplierProducts.length >
                                                1
                                                    ? 's'
                                                    : ''}{' '}
                                                to shop
                                            </Button>
                                        </Flex>
                                    )}
                            </>
                        )}
                        {showForm && (
                            <>
                                <FormProduct
                                    formData={selectedSupplierProducts}
                                    onSubmitted={handleSuccessfulSubmit}
                                />
                            </>
                        )}
                    </Modal.Content>
                </>
            )}
        </Modal>
    );
};
