import React, { useEffect, useState } from 'react';

import { Flex, FormControl, TextInput } from '@contentful/f36-components';
import { SearchIcon } from '@contentful/f36-icons';

import { ENV } from '../../environments';
import { useDebounce, useVetdeskMiddleware } from '../../hooks';
import {
    ApiListResponse,
    SupplierProduct,
    SupplierProductsFilters,
} from '../../models';
import {
    DEFAULT_FETCH_LIST_FILTERS,
    getQueryString,
} from '../../utilities/fetch-filters';
import { SUPPLIER_PRODUCT_COLUMNS } from '../../utilities/table-columns';
import { PaginationWrapper } from '../pagination-wrapper';
import { TableList } from '../table-list';
import { CategoryPickerSupplier } from './category-picker-supplier';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/supplier/product`;

export interface ProductPickerProps {
    supplierId: string;
    onProductSelect: (supplierProducts: SupplierProduct[]) => void;
}

export const ProductPicker: React.FC<ProductPickerProps> = ({
    supplierId,
    onProductSelect,
}) => {
    const [products, setProducts] =
        useState<ApiListResponse<SupplierProduct>>();
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<SupplierProductsFilters>({
        supplierGuid: supplierId,
        ...DEFAULT_FETCH_LIST_FILTERS,
    });
    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);

    const { fetchEntityList } =
        useVetdeskMiddleware<ApiListResponse<SupplierProduct>>();

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const handleCategoryChange = (categoryId: string) => {
        setFilters({
            ...filters,
            offset: 0,
            categoryId: categoryId,
        });
    };

    const handlePageChange = (page: number) => {
        setProducts(undefined); // to display skeleton when changing page
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const fetchSupplierProducts = () => {
        fetchEntityList(`${API_URL}${getQueryString(filters)}`).then((res) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setProducts(res as any);
        });
    };

    useEffect(() => {
        fetchSupplierProducts();
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchSupplierProducts();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            setFilters({
                ...filters,
                offset: 0,
                search: debouncedSearchInput,
            });
        }
    }, [debouncedSearchInput]);

    return (
        <Flex className="spacingTop" flexDirection="column">
            <FormControl.Label>Pick one or more products</FormControl.Label>
            <Flex flexDirection="row" gap="spacingL" style={{ height: 42 }}>
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search..."
                    icon={<SearchIcon />}
                />
                <CategoryPickerSupplier
                    supplierId={supplierId}
                    onCategorySelect={(c) => handleCategoryChange(c?.id || '')}
                />
            </Flex>
            <TableList<SupplierProduct>
                columns={SUPPLIER_PRODUCT_COLUMNS}
                items={products?.items}
                onSelectionChange={onProductSelect}
            />
            {products && (
                <PaginationWrapper
                    onPageChange={handlePageChange}
                    filters={filters}
                    totalItems={products.total}
                />
            )}
        </Flex>
    );
};
